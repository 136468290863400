"use strict";
/**
 * @namespace
 */
var planactor = {
    /**
     * global 정규식
     * @namespace
     */
    regexp : {
        /**
         * 영문 체크 정규식
         * @member planactor.regexp.alpha
         */
        alpha : /^[a-zA-Z]*$/,
        /**
         * 영문+숫자 체크 정규식
         * @member planactor.regexp.alnum
         */
        alnum : /^[a-zA-Z0-9]*$/,
        /**
         * 이메일 체크 정규식
         * @member planactor.regexp.email
         */
        email : /\w{1,}[@][\w\-]{1,}([.]([\w\-]{1,})){1,3}$/,
        /**
         * 주민등록번호 체크 정규식
         * @member planactor.regexp.rrn
         */
        rrn : /^(\d{6})-?([1234]\d{6})$/,
        /**
         * 사업자등록번호 체크 정규식
         * @member planactor.regexp.brn
         */
        brn : /^(\d{3})-?(\d{2})-?(\d{5})$/,
        /**
         * 호스트네임(URL:https,ftp,telnet,mms) 체크 정규식
         * @member planactor.regexp.hostname
         */
        hostname : /(?:(?:((https?|ftp|telnet|mms):\/\/)?|[\s\t\r\n\[\]\`\<\>\"\'])((?:[\w$\-_\.+!*\'\(\),]|%[0-9a-f][0-9a-f])*\:(?:[\w$\-_\.+!*\'\(\),;\?&=]|%[0-9a-f][0-9a-f])+\@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9\-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?:\:([0-9]+))?((?:\/(?:[\w$\-_\.+!*\'\(\),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s\/\?\.:<>|#]*(?:\.[^\s\/\?:<>|#]+)*))?(\/?[\?;](?:[a-z0-9\-]+(?:=[^\s:&<>]*)?\&)*[a-z0-9\-]+(?:=[^\s:&<>]*)?)?(#[\w\-]+)?)/im,
        /**
         * 날자형태 체크 정규식 (yyyy-mm-dd 형태)
         * @member planactor.regexp.date
         */
        date : /^(\d{4})[-\.\/\s](\d{1,2})[-\.\/\s](\d{1,2})$/
    },
    /**
     * html5 File, FileList, FileReader, Blob 지원여부 체크
     * @example
     * planactor.isUseFileApi // 지원할 경우 true, 나머지 false
     */
    isUseFileApi : window.File && window.FileList && window.FileReader && window.Blob,
    /**
     * 공백문자열이나 undefined, null일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isEmpty : function(value){
        return (null == value || '' == String(value).trim());
    },
    /**
     * 문자열이 비어있지 않은 경우 true, 비어있을 경우 false 반환
     * @param value
     * @returns {boolean}
     */
    isNotEmpty : function(value){
        return !planactor.isEmpty(value);
    },
    /**
     * value가 function 타입일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isFunction : function(value){
        return ('function' == typeof value);
    },
    /**
     * value가 object 타입일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isObject : function(value){
        return ('object' == typeof value);
    },
    /**
     * value가 array 타입일 경우 true, 나머지 false 반환
     * @param value
     * @returns {Boolean}
     */
    isArray : function(value){
        return (value instanceof Array);
    },
    /**
     * value가 정수일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isDigit : function(value){
        return /^[0-9]*$/.test(value);
    },
    /**
     * value가 number 타입일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isNumber : function(value){
        return !isNaN(parseFloat(value)) && isFinite(value);
    },
    /**
     * value가 string 타입일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isString : function(value){
        return ('string' == typeof value);
    },
    /**
     * value가 json 문자열 타입일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isJson : function(value){
        if (planactor.isEmpty(value)) return false;
        value = value.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
        value = value.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']');
        value = value.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
            return (/^[\],:{}\s]*$/).test(value);
    },
    /**
     * value가 alpha(영문조합) 형태일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isAlpha : function(value){
        return planactor.regexp.alpha.test(value);
    },
    /**
     * value가 alnum(영문+숫자조합) 형태일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isAlnum : function(value){
        return planactor.regexp.alnum.test(value);
    },
    /**
     * value가 이메일 형태일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isEmail : function(value){
        return planactor.regexp.email.test(value);
    },
    /**
     * value가 hostname(url) 형태일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isHostname : function(value){
        return planactor.regexp.hostname.test(value);
    },
    /**
     * value가 (yyyy-mm-dd) 형태로 실제 date일 경우 true, 나머지 false 반환
     * @param {string} value
     * @returns {boolean}
     * @example
     * planactor.isDate('2017-02-29') // false
     * planactor.isDate('2016-02-29') // true
     */
    isDate : function(value){
        value = String(value);
        var regexp = planactor.regexp.date;
        if (!regexp.test(value)) {
            return false;
        }
        var d = new Date(value.replace(regexp, '$2/$3/$1'));
        return (parseInt(RegExp.$2, 10) == (1+d.getMonth()))
        && (parseInt(RegExp.$3, 10) == d.getDate())
        && (parseInt(RegExp.$1, 10) == d.getFullYear());
    },
    /**
     * value가 한국 원화(KRW) 형태일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isCurrency : function(value){
        return (0 == value) || /^[\-\+]?([1-9]{1}[0-9]{0,2}((\,[0-9]{3})*|([0-9])*))$/.test(value);
    },
    /**
     * value가 미국 달러(USD) 형태일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isCurrencyUSD : function(value){
        return (0 == value) || /^[\-\+]?[\$]?([1-9]{1}[0-9]{0,2}(\,[0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}\d*(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/.test(value);
    },
    /**
     * value가 중국 위안(CNY) 형태일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isCurrencyCNY : function(value){
        return (0 == value) || /^[\-\+]?[\u00a5]?([1-9]{1}[0-9]{0,2}(\,[0-9]{3})*(\.[0-9]{0,2})?|[1-9]{1}\d*(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/.test(value);
    },
    /**
     * value가 regexp 객체형태일 경우 true, 나머지 false 반환
     * @param value
     * @returns {boolean}
     */
    isRegexp : function(value){
        return (value instanceof RegExp);
    },
    /**
     * value 문자열 길이이 min~max 범위에 포함될 경우 true, 나머지 false 반환
     * 최소 isLength(2) 최소 2보다 커야될 경우
     * 최대 isLength(0,10) 최대 10보다 작아야될 경우
     * @param {string|number} value
     * @param {number} min
     * @param {number} max
     * @returns {boolean}
     * @example
     * planactor.isLength('string',3,5) // false 문자열이 3~5자리 이내일 경우
     * planactor.isLength('string',2) // true
     */
    isLength : function(value, min, max){
        value = String(value);
        if (planactor.isEmpty(min)) min = 0;
        return (value.length >= min && (planactor.isEmpty(max) || value.length <= max));
    },
    /**
     * value 숫자가 min~max 범위에 포함될 경우 true, 나머지 false 반환
     * @param {number} value
     * @param {number} min
     * @param {number} max
     * @returns {boolean}
     * @example
     * planactor.isRange(30,10,31) // true
     */
    isRange : function(value, min, max){
        if (false == planactor.isNumber(value)) return false;
        value = Number(value);
        if (planactor.isEmpty(min)) min = 0;
        return (value >= Number(min) && (planactor.isEmpty(max) || value <= Number(max)));
    },
    /**
     * value가 주민등록번호 형태일 경우 true, 나머지 false 반환
     * @param {string} value 주민등록번호
     * @returns {boolean}
     */
    isRrn : function(value){
        value = String(value);
        if (!planactor.regexp.rrn.test(value)) return false;
        value = value.replace(/-/g,'');
        var year = ((value.charAt(6) <= "2") ? "19" : "20") + value.substr(0, 2);
        var month = value.substr(2, 2);
        var day = value.substr(4, 2);
        var birth = year + '/' + month + '/' + day;
        if (!planactor.isDate(birth)) return false;
        var weight = '234567892345';
        var sum = 0;
        for (var i = 0; i < 12; i++) {
            sum = sum + (parseInt(value.substr(i, 1)) * parseInt(weight.substr(i, 1)));
        }
        var result = 11 - (sum % 11);
        if (result == 10) result = 0;
        else if (result == 11) result = 1;
        if (result != parseInt(value.substr(12, 1))) return false;
        return true;
    },
    /**
     * value가 사업자등록번호 형태일 경우 true, 나머지 false 반환
     * @param {string} value 사업자등록번호
     * @returns {boolean}
     */
    isBrn : function(value){
        value = String(value);
        if (!planactor.regexp.brn.test(value)) return false;
        value = value.replace(/-/g,'');
        var weight = '137137135';
        var sum = 0;
        for (var i = 0; i < 9; i++) sum += (parseInt(value.substr(i, 1)) * parseInt(weight.substr(i, 1)));
        sum += parseInt((value.substr(8, 1) * 5) / 10);
        if ((10 - sum % 10) % 10 != value.substr(9, 1)) return false;
        return true;
    },
    /**
     * element가 화면에 표시되는 경우 true, 나머지 false 반환
     * @param {element} element
     * @returns {boolean}
     */
    isDisplay : function(element){
        while(element && 'BODY' != element.tagName) {
            if('none' == element.style.display || 'hidden' == element.style.visibility){
                return false;
            }
            element = element.parentNode;
        }
        return true;
    },
    /**
     * value가 한국식 휴대전화번호 형태일 경우 true, 나머지 false 반환
     * @param {string} value 휴대전화번호
     * @returns {boolean}
     */
    isPhone : function(value){
        return (planactor.isLength(value, 8, 14) && (
            /^(02|0[3-8][0-5]|050[256]|01[016-9])-?([0-9]{3,4})-?([0-9]{4})$/.test(value) ||
            /^(1544|1566|1577|1588|1599|1600|1644|1688)-?([0-9]{4})$/.test(value)
        ));
    },
    /**
     * value가 한국식 휴대전화번호 형태일 경우 true, 나머지 false 반환
     * @param {string} value 휴대전화번호
     * @returns {boolean}
     */
    isMobile : function(value){
        value = String(value);
        return (this.isLength(value, 10, 14) &&
            /^(050[256]|01[016-9])-?([1-9]{1}[0-9]{2,3})-?([0-9]{4})$/.test(value));
    },
    /**
     * ActiveX 설치여부 확인
     * @param {string} progid servername.typename {@link https://msdn.microsoft.com/ko-kr/library/7sw4ddf8(v=VS.94).aspx}
     * @returns {boolean}
     */
    isActivexInstalled : function(progid){
        var installed;
        try {
            var activexObj = new ActiveXObject(progid);
            if(activexObj){
                installed = true;
            } else {
                installed = false;
            }
        } catch (e) {
            installed = false;
        }
        return installed;
    },
    /**
     * 전화번호 형식으로 변환 (01012341234 => 010-1234-1234)
     * @param {string|number} 전화번호
     * @return {string} 변환된 전화번호
     */
    toPhone : function(value){
        value = String(value);
        return value.replace(/-/g,'').replace(
            /^(02|0[3-8][0-5]|050[256]|01[016-9])-?([0-9]{3,4})-?([0-9]{4})$/, '$1-$2-$3'
        ).replace(
            /^(1544|1566|1577|1588|1599|1600|1644|1688)-?([0-9]{4})$/, '$1-$2'
        );
    },
    /**
     * value에 대해 number 타입 형태의 문자열로 반환
     * @param {number} value 변환할 숫자
     * @param {number} decimals 소수점 표시 자릿수
     * @param {string} dec_point 소수점 구분자(.)
     * @param {string} thousands_sep 천단위 구분자(,)
     * @returns {string}
     * @example
     * planactor.toNumber(12345.1234, 2) // 12,345.12
     * planactor.toNumber(12345.5134) // 12,346
     */
    toNumber : function (value, decimals, dec_point, thousands_sep) {
        var n = value, c = isNaN(decimals = Math.abs(decimals)) ? 0 : decimals;
        var d = dec_point == undefined ? "." : dec_point;
        var t = thousands_sep == undefined ? "," : thousands_sep, s = n < 0 ? "-" : "";
        var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    },
    /**
     * value 호스트에 대해 프로토콜을 포함한 호스트로 변환
     * @param {string} value 호스트네임(url) 문자열
     * @param {string} protocal 기본 프로토콜(http://)
     * @returns {string}
     */
    toHostname : function(value, protocal){
        value = String(value);
        if (!ptotocal) protocal = 'http://';
        var match = value.match(planactor.regexp.hostname);
        return (match) ? (!match[1]) ? protocal+match[0] : match[0] : value;
    },
    /**
     * byte용량에 대한 단위 변환 (GB,MB 등)
     * @param {number} bytes
     * @param {number} decimals 표시 소수점 자릿수 지정(default:2)
     * @returns {string} 변환된 용량
     */
    toByteUnit : function (bytes, decimals){
        decimals = (decimals > 0)?decimals:2;
        if (bytes == 0) return '0 Byte';
        var k = 1024; // byte, KB 단위는 1024 씩 증가
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
        return (bytes / Math.pow(k, i)).toFixed(decimals) + ' ' + sizes[i];
    },
    /**
     * 전송속도 bps 단위변환 (bit per second)
     * @param {number} bps
     * @returns {string} 변환된 전송속도
     */
    toBpsUnit : function (bps){
        if(bps == 0) return '0 bps';
        var bits = bps*8; // bytes -> bit 변환
        var k = 1000; // bps, Kbps 단위는 1000 씩 증가
        var sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
        var i = Math.floor(Math.log(bits) / Math.log(k));
        return (bits / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    },
    /**
     * 쿠키저장
     * @param {string} name 쿠키명
     * @param {number|string|object|array} value 쿠키 value
     * @param {object} options 옵션(예제참고)
     * @returns {planactor}
     * @example
planactor.setCookit('name',{},{
    expires : 86400, // 86400초후 만료(초단위, 기본 1일)
    path : '/',     // 쿠키가 적용될 사이트 내 패스 (/site/)
    domain : null,  // 쿠키를 적용할 도메인
    secure : false  // https
});
planactor.setCookit('name','value')
     */
    setCookie : function(name, value, options) {
        options = planactor.extend({
            expires : 86400,
            path : '/',
            domain : null,
            secure : false
        }, options);
        if (!planactor.isString(name)) throw new Error('Planactor.setCookie name is not defined.');
        switch(typeof value){
            case 'array': case 'object':
                value = planactor.toJson(value);
                break;
            default:
        }
        if(options.expires > 0){
            var timestamp = 1000*options.expires;
            var today = new Date();
            options.expires = new Date();
            options.expires.setTime(today.getTime()+timestamp);
        }
        var cookie = name + "=" + escape(value) +
            ((options.expires) ? "; expires=" + options.expires.toGMTString() : "") +
            ((options.path) ? "; path=" + options.path : "") +
            ((options.domain) ? "; domain=" + options.domain : "") +
            ((options.secure) ? "; secure" : "");
        document.cookie = cookie;
        return this;
    },
    /**
     * 저장된 쿠키 데이터 로드
     * @param {string} name
     * @returns {number|string|array|object}
     * @example
     * planactor.getCookit('name')
     */
    getCookie : function(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1){
            begin = dc.indexOf(prefix);
            if (begin != 0) return null;
        }else begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) end = dc.length;
        var value = unescape(dc.substring(begin + prefix.length, end));
        if (planactor.isJson(value)) return planactor.fromJson(value);
        return value;
    },
    /**
     * 저장된 쿠키삭제
     * @param {string} name
     * @param {object} options 옵션(예제참고)
     * @returns {planactor}
     * @example
planactor.removeCookie('name',{
    path : '/',     // 쿠키가 보관되어 있는 사이트 내 패스 (/site/)
    domain : null   // 쿠키가 적용된 사이트정보
})
planactor.removeCookie('name')
     */
    removeCookie : function(name, options) {
        if(planactor.getCookie(name)){
            options = planactor.extend({
                path : '/',
                domain : null
            }, options);
        var cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
            ((options.path) ? '; path=' + options.path : '') +
            ((options.domain) ? '; domain=' + options.domain : '');
        document.cookie = cookie;
        }
        return this;
    },
    /**
     * 문자열 byte 계산 (한글 2byte 계산)
     * @param {string} value byte를 계산할 문자열
     * @returns {number} 문자열 byte 수
     */
    bytes : function(value){
        value = String(value);
        var length = 0;
        for(var i=0; i<value.length; ++i){
            length += (value.charCodeAt(i) > 128) ? 2 : 1;
        }
        return length;
    },
    /**
     * 문자열 자르기
     * @param {string} value 문자열
     * @param {number} length 표시할 자릿 수(나머지 제외)
     * @returns {string}
     */
    strcut : function(value, length){
        value = String(value);
        var l = 0;
        for (var i=0; i<value.length; i++) {
            l += (value.charCodeAt(i) > 128) ? 2 : 1;
            if (l > length) return value.substring(0,i);
        }
        return value;
    },
    /**
     * rgb를 hexcode로 변환
     * @param {number} r (0~255)
     * @param {number} g (0~255)
     * @param {number} b (0~255)
     * @returns {string} hexcode
     */
    toRgbToHex : function(r,g,b){
        var r = parseInt(r);
        var g = parseInt(g);
        var b = parseInt(b);
        var rgb = b | (g << 8) | (r << 16);
        return '#' + rgb.toString(16);
    },
    /**
     * rgb를 cmyk로 변환
     * @param {number} r (0~255)
     * @param {number} g (0~255)
     * @param {number} b (0~255)
     * @returns {array} [c,m,y,k]
     */
    toRgbToCmyk : function(r,g,b){
        var C = 0, M = 0, Y = 0, K = 0;
        var r = parseInt(r,10);
        var g = parseInt(g,10);
        var b = parseInt(b,10);

        if (r<0 || g<0 || b<0 || r>255 || g>255 || b>255) {
            throw new TypeError('RGB values must be in the range 0 to 255.');
        }

        // BLACK
        if (r==0 && g==0 && b==0) {
            K = 1;
            return [0,0,0,1];
        }
        C = 1 - (r/255);
        M = 1 - (g/255);
        Y = 1 - (b/255);

        var minCMY = Math.min(C,Math.min(M,Y));
        C = (C - minCMY) / (1 - minCMY) ;
        M = (M - minCMY) / (1 - minCMY) ;
        Y = (Y - minCMY) / (1 - minCMY) ;
        K = minCMY;

        return [C,M,Y,K];
    },
    /**
     * hexcode를 rgb 코드로 변경
     * @param {string} hexcode (#ffffff)
     * @return {array} [r,g,b]
     */
    toHexToRgb : function(hex){
        hex = hex.replace('#','');

        if (6 === hex.length){
            var r = parseInt(hex.substring(0,2),16);
            var g = parseInt(hex.substring(2,4),16);
            var b = parseInt(hex.substring(4,6),16);
        } else if (3 <= hex.length) {
            var hexr = hex.substring(0,1), r = parseInt(hexr+hexr,16);
            var hexg = hex.substring(1,2), g = parseInt(hexg+hexg,16);
            var hexb = hex.substring(2,3), b = parseInt(hexb+hexb,16);
        } else {
            throw new TypeError('HEX values must be in the length 3 or 6');
        }
        return [r,g,b];
    },
    /**
     * hexcode => cmyk 변환
     * @param {string} hex 변환할 hexcode
     * @returns {array} [c,m,y,k]
     */
    toHexToCmyk : function(hex){
        var rgb = planactor.toHexToRgb(hex);
        return planactor.rgbToCmyk(rgb[0], rgb[1], rgb[2]);
    },
    /**
     * inch => mm 단위환산
     * @param {number} inch 변환할 inch
     * @returns {number} 밀리미터(mm)
     */
    toInchToMm : function(inch){
        return Number(inch)*25.4;
    },
    /**
     * inch => px 단위환산
     * @param {number} inch 변환할 인치
     * @param {number} dpi 적용 DPI
     * @return {number} 픽셀(px)
     */
    toInchToPx : function(inch, dpi){
        return Number(inch)*Number(dpi);
    },
    /**
     * mm => inch 단위환산
     * @param {number} mm 변환할 MM
     * @return {number} 인치(inch)
     */
    toMmToInch : function(mm){
        return Number(mm)/25.4;
    },
    /**
     * mm => px 단위환산
     * @param {number} mm 변환할 MM
     * @param {number} dpi 적용 DPI
     * @return {number} 픽셀(px)
     */
    toMmToPx : function(mm, dpi){
        return Number(mm)/25.4*Number(dpi);
    },
    /**
     * px => inch 단위환산
     * @param {number} px 변환할 PX
     * @param {number} dpi 적용 DPI
     * @return {number} 인치(inch)
     */
    toPxToInch : function(px, dpi){
        return Number(px)/Number(dpi);
    },
    /**
     * px => mm 단위환산
     * @param {number} px 변환할 PX
     * @param {number} dpi 적용 DPI
     * @return {number} 밀리미터(mm)
     */
    toPxToMm : function(px, dpi){
        return Number(px)/Number(dpi)*25.4;
    },
    /**
     * 이미지 등 리사이즈
     * @param {number} width 가로크기
     * @param {number} height 세로크기
     * @param {number} maxWidth 최대 가로크기
     * @param {number} maxHeight 최대 세로크기
     * @param {boolean} scalable default: false
     * @return {object}
     * @example
// true일 경우 maxWidth,maxHeight가 width,height 클경우 비율적으로 키운다
planactor.resize(100,200,400,400,true) // {'width':200,'height':400}
// false일 경우 최대크기는 width,height로 제한
planactor.resize(100,200,400,400,false) // {'width':100,'height':200}
     */
    resize : function(width, height, maxWidth, maxHeight, scalable){
        var scaleX = maxWidth / width;
        var scaleY = maxHeight / height;
        var scale = (scaleX < scaleY) ? scaleX : scaleY;
        if (true !== scalable){
            if (scale > 1) scale = 1;
        }
        return {'width': scale*width,'height': scale*height};
    },
    /**
     * 익명함수
     * @function
     */
    noop : function(){},
    /**
     * 동기식 배열순환
     * @param {array} rows
     * @param {function} callback
     * @param {function} complete
     * @example
planactor.sync(rowset, function(i, row, next){
    console.log(row);
    setTimeout(function(){
        next(); // 다음 row 호출
    }, 1000);
}, function(){
    console.log('complete');
});
     */
    sync : function(rows, callback, complete, index){
        index = index || 0;
        callback.call(rows[index], parseInt(index), rows[index], function(){
            if (++index < rows.length){
                planactor.sync(rows, callback, complete, index);
            }else{
                complete();
            }
        });
    },
    /**
     * Array 및 Object 순환탐색
     * @param {array|object} object 순환활 배열 및 객체
     * @param {function} callback 매 순환 시 호출될 function
     * @param {object} bind callback this 객체 (default: window)
     * @returns {planactor}
     * @example
planactor.each(array, function(val, i, array){
// this===bind
    console.log(val, i);
}, bind);
planactor.each(array, function(val){
    console.log(val);
});
     */
    each : function(object, callback/*, bind */){
        if (null == object) {
            throw new TypeError('planactor.each: object is null or undefined');
        }
        if (!planactor.isFunction(callback)) {
            throw new TypeError('planactor.each: callback must be a function');
        }
        var bind = arguments[2];
        // array
        if (planactor.isArray(object)){
            var length = object.length;
            for(var i=0; i<length; ++i){
                callback.call(bind, object[i], i, object);
            }
        }
        // hash object
        else {
            for(var i in object){
                if (object.hasOwnProperty(i)){
                    callback.call(bind, object[i], i, object);
                }
            }
        }
        return this;
    },
    /**
     * callback 리턴 중 하나라도 true이면 즉시 true, 나머지 false 반환
     * @param {array|object} object 순환할 배열 및 객체
     * @param {function} callback 매 순환 시 호출될 function
     * @param {object} bind callback this 객체 (default: window)
     * @returns {planactor}
     * @example
// return true
var rs = planactor.any([1,2,3], function(val){
    return 2 == val;
});
// return false
var rs = planactor.any([1,2,3], function(val){
    return 0 == val;
});
     */
    any : function(object, callback/*, bind */){
        if (null == object) {
            throw new TypeError('planactor.any: object is null or undefined');
        }
        if (!planactor.isFunction(callback)) {
            throw new TypeError('planactor.any: callback must be a function');
        }
        var bind = arguments[2];
        if (planactor.isArray(object)) {
            var length = object.length;
            for(var i=0; i<length; ++i){
                if (true === callback.call(bind, object[i], i, object)){
                    return true;
                }
            }
        } else {
            for(var i in object){
                if (object.hasOwnProperty(i)){
                    if (true === callback.call(bind, object[i], i, object)){
                        return true;
                    }
                }
            }
        }
        return false;
    },
    /**
     * callback 리턴 중 하나라도 false이면 즉시 false 반환
     * @param {array|object} object 순환할 배열 및 객체
     * @param {function} callback 매 순환 시 호출될 function
     * @param {object} bind callback this 객체 (default: window)
     * @returns {planactor}
     * @example
// return true
var rs = planactor.all([1,2,3], function(val){
    return planactor.isDigit(val);
});
// return false
var rs = planactor.all([1,2,null], function(val){
    return planactor.isDigit(val);
});
     */
    all : function(object, callback/*, bind */){
        if (null == object) {
            throw new TypeError('planactor.all: object is null or undefined');
        }
        if (!planactor.isFunction(callback)) {
            throw new TypeError('planactor.all: callback must be a function');
        }
        var bind = arguments[2];
        if (planactor.isArray(object)) {
            var length = object.length;
            for(var i=0; i<length; ++i){
                if (false === callback.call(bind, object[i], i, object)){
                    return false;
                }
            }
        } else {
            for(var i in object){
                if (object.hasOwnProperty(i)){
                    if (false === callback.call(bind, object[i], i, object)){
                        return false;
                    }
                }
            }
        }
        return true;
    },
    /**
     * callback 반환값이 true인 값을 리턴
     * @param {array} array 순환할 배열 및 객체
     * @param {function} callback 매 순환 시 호출될 function
     * @param {object} bind callback this 객체 (default: window)
     * @returns {findValue|undefined}
     */
    find : function(array, callback/*, bind */){
        if (!planactor.isArray(array)) {
            throw new TypeError('planactor.find: object must be a array');
        }
        if (!planactor.isFunction(callback)) {
            throw new TypeError('planactor.find: callback must be a function');
        }
        var bind = arguments[2];
        var length = array.length;
        for(var i=0; i<length; ++i){
            if(true === callback.call(bind, array[i], i, array)) return array[i];
        }
        return undefined;
    },
    /**
     * callback 반환값이 true인 값들의 배열을 리턴
     * @param {array} array 순환할 배열 및 객체
     * @param {function} callback 매 순환 시 호출될 function
     * @param {object} bind callback this 객체 (default: window)
     * @returns {array} 찾는값이 없을 경우 [] 반환
     */
    findAll : function(array, callback/*, bind */){
        if (!planactor.isArray(array)) {
            throw new TypeError('planactor.findAll: object must be a array');
        }
        if (!planactor.isFunction(callback)) {
            throw new TypeError('planactor.findAll: callback must be a function');
        }
        var bind = arguments[2];
        var arr = [];
        var length = array.length;
        for(var i=0; i<length; ++i){
            (true === callback.call(bind, array[i], i, array)) && arr.push(array[i]);
        }
        return arr;
    },
    /**
     * callback 반환값을 배열로 리턴
     * @param {array} array 순환할 배열 및 객체
     * @param {function} callback 매 순환 시 호출될 function
     * @param {object} bind callback this 객체 (default: window)
     * @returns {array} undefined를 포함한 배열 반환
     */
    map : function(array, callback/*, bind */){
        if (!planactor.isArray(array)) {
            throw new TypeError('planactor.map: object must be a array');
        }
        if (!planactor.isFunction(callback)) {
            throw new TypeError('planactor.map: callback must be a function');
        }
        var bind = arguments[2];
        var arr = [];
        var length = array.length;
        for(var i=0; i<length; ++i){
            arr.push(callback.call(bind, array[i], i, array));
        }
        return arr;
    },
    /**
     * object 객체를 배열 형태로 변환
     * @param {object} object
     * @returns {array}
     */
    toArray : function(obj){
        if (planactor.isArray(obj)) return obj;
        var arr = [], index = 0;
        for(var i in obj){
            if (obj.hasOwnProperty(i)){
                arr[i] = obj[i];
                ++index;
            }
        }
        arr.length = index;
        return arr;
    },
    /**
     * object 객체 확장
     * @param {object} target
     * @param {arguments} arguments
     * @returns {object}
     * @example
     * planactor.extend(obj, {}, ...);
     */
    extend : function(target){
        if (target == null){
            throw new TypeError('Cannot convert undefined or null to object');
        }
        target = Object(target);
        var length = arguments.length;
        for (var index = 1; index < length; ++index){
            var source = arguments[index];
            if (null != source){
                for (var key in source){
                    if (Object.prototype.hasOwnProperty.call(source,key)){
                        target[key] = source[key];
                    }
                }
            }
        }
        return target;
    },
    /**
     * Cross-Browser DOMContentLoaded
     * @param {function} callback DOM 로드 후 실행될 함수
     * @param {window} DOM이 속해있는 window 객체
     * @returns {planactor}
     * @example
planactor.ready(function(){
});
//윈도우 바인딩
planactor.ready(function(){
}, win);
     */
    ready : function(callback, win){
        if (!win) win = window;
        var done = false, top = true,
        doc = win.document,
        root = doc.documentElement,
        modern = doc.addEventListener,

        add = modern ? 'addEventListener' : 'attachEvent',
        rem = modern ? 'removeEventListener' : 'detachEvent',
        pre = modern ? '' : 'on',

        init = function(e) {
            if (e.type == 'readystatechange' && doc.readyState != 'complete') return;
            (e.type == 'load' ? win : doc)[rem](pre + e.type, init, false);
            if (!done && (done = true)) callback.call(win, e.type || e);
        },

        poll = function() {
            try { root.doScroll('left'); } catch(e) { setTimeout(poll, 50); return; }
            init('poll');
        };

        if (doc.readyState == 'complete') callback.call(win, 'lazy');
        else {
            if (!modern && root.doScroll) {
                try { top = !win.frameElement; } catch(e) { }
                if (top) poll();
            }
            doc[add](pre + 'DOMContentLoaded', init, false);
            doc[add](pre + 'readystatechange', init, false);
            win[add](pre + 'load', init, false);
        }
        return this;
    },
    /**
     * file blobSlice
     * @param {file} file
     * @param {number} size
     * @returns {filePart} filePart
     * @example
     * planactor.blobSlice(file, size);
     */
    blobSlice : function(file, slice){
        try {
            if (file instanceof Blob){
                var size = Math.min(file.size, slice || 262144);
                file = (file.slice || file.mozSlice || file.webkitSlice).call(file, 0, size);
            }
            return file;
        }catch(e){
            throw new Error('planactor.blobSlice Error!');
        }
    },
    /**
     * Object to Json String
     * {@link https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify}
     * @param {object} object Json 문자열로 변경할 Object
     * @param {function} reviver
     * @param {number} space
     * @returns {string}
     */
    toJson : function(){
        return JSON.stringify.apply(null,arguments);
    },
    /**
     * Json String to Object
     * {@link https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse}
     * @param {string} text Json 문자열
     * @param {function} reviver
     * @returns {object}
     */
    fromJson : function(){
        return JSON.parse.apply(null,arguments);
    }
};

(function(planactor){
    /** @namespace planactor.currency */

    /**
     * 통화 API
     * @member planactor.currency
     * @function
     * @param {number} number
     * @param {string} ISO (KRW, CNY, USD 등)
     * @example
     * planactor.currency(1000);
     * planactor.currency(1000, "CNY");
     */
    planactor.currency = function(number, iso){
        iso = iso || planactor.currency.ISO;
        var decimals = 0;
        if (planactor.currency.config[iso] && planactor.isDigit(planactor.currency.config[iso]["decimals"])){
            decimals = planactor.currency.config[iso]["decimals"];
        }
        return planactor.toNumber(number, decimals);
    };
    /**
     * 통화 API
     * @member planactor.toCurrency
     * @function
     * @param {number} number
     * @param {string} ISO (KRW, CNY, USD 등)
     * @example
     * planactor.toCurrency(1000);
     * planactor.toCurrency(1000, "CNY");
     */
    planactor.toCurrency = planactor.currency;
    /**
     * planactor.currency.ISO_KRW (대한민국 한화 ISO)
     * @member planactor.currency.ISO_KRW
     */
    planactor.currency.ISO_KRW = 'KRW';
    /**
     * planactor.currency.ISO_CNY (중국 인민폐 ISO)
     * @member planactor.currency.ISO_CNY
     */
    planactor.currency.ISO_CNY = "CNY";
    /**
     * planactor.currency.ISO_USD (미국 달러 ISO)
     * @member planactor.currency.ISO_USD
     */
    planactor.currency.ISO_USD = "USD";
    /**
     * planactor.currency.ISO (기본 ISO 지정 : KRW)
     * @member planactor.currency.ISO
     */
    planactor.currency.ISO = planactor.currency.ISO_KRW;
    /**
     * planactor.currency.config
     * @member planactor.currency.config
     * @example
planactor.currency.config = {
    "KRW" : {
        "iso"       : planactor.currency.ISO_KRW,
        "symbol"    : "₩",
        "decimals"  : 0,
        "string"    : "원"
    },
    "CNY" : {
        "iso"       : planactor.currency.ISO_CNY,
        "symbol"    : "¥",
        "decimals"  : 2,
        "string"    : "元"
    },
    "USD" : {
        "iso"       : planactor.currency.ISO_USD,
        "symbol"    : "$",
        "decimals"  : 2,
        "string"    : "dollar"
    }
};
     */
    planactor.currency.config = {
        "KRW" : {
            "iso"       : planactor.currency.ISO_KRW,
            "symbol"    : "₩",
            "decimals"  : 0,
            "string"    : "원"
        },
        "CNY" : {
            "iso"       : planactor.currency.ISO_CNY,
            "symbol"    : "¥",
            "decimals"  : 2,
            "string"    : "元"
        },
        "USD" : {
            "iso"       : planactor.currency.ISO_USD,
            "symbol"    : "$",
            "decimals"  : 2,
            "string"    : "dollar"
        }
    };
})(planactor || new Object());


if (XMLHttpRequest && !XMLHttpRequest.prototype.sendAsBinary) {
    /**
     * @class XMLHttpRequest
     */
    /**
     * @memberof XMLHttpRequest
     * @function
     * @deprecated Deprecated since Gecko 31 (Firefox 31 / Thunderbird 31 / SeaMonkey 2.28)
     */
    XMLHttpRequest.prototype.sendAsBinary = function(sData) {
        var nBytes = sData.length, ui8Data = new Uint8Array(nBytes);
        for (var nIdx = 0; nIdx < nBytes; nIdx++) {
            ui8Data[nIdx] = sData.charCodeAt(nIdx) & 0xff;
        }
        this.send(ui8Data);
    };
}

/**
 * @name Number
 * @class Number
 */
planactor.extend(Number.prototype, {
    /**
     * @memberof Number
     * @returns {boolean}
     */
    isNumber : function(){
        return planactor.isNumber(this);
    },
    /**
     * @memberof Number
     * @returns {boolean}
     */
    isDigit : function(){
        return planactor.isDigit(this);
    },
    /**
     * @memberof Number
     * @param {number} min
     * @param {number} max
     * @returns {boolean}
     */
    isLength : function(min, max){
        return planactor.isLength(this, min, max);
    },
    /**
     * @memberof Number
     * @param {number} min
     * @param {number} max
     * @returns {boolean}
     */
    isRange : function(min, max){
        return planactor.isRange(this, min, max);
    },
    /**
     * 주민등록번호 형태일 경우 true, 나머지 false 반환
     * @memberof Number
     * @returns {boolean}
     */
    isRrn : function(){
        return planactor.isRrn(this);
    },
    /**
     * 사업자등록번호 형태일 경우 true, 나머지 false 반환
     * @memberof Number
     * @returns {boolean}
     */
    isBrn : function(){
        return planactor.isBrn(this);
    },
    /**
     * 한국식 휴대번호 형태일 경우 true, 나머지 false 반환
     * @memberof Number
     * @returns {boolean}
     */
    isMobile : function(){
        return planactor.isMobile(this);
    },
    /**
     * 한국식 전화번호 형태일 경우 true, 나머지 false 반환
     * @memberof Number
     * @returns {boolean}
     */
    isPhone : function(){
        return planactor.isPhone(this);
    },
    /**
     * @memberof Number
     * @param {number} decimals
     * @param {string} dec_point
     * @param {string} thousands_sep
     * @returns {string}
     */
    toNumber : function (decimals, dec_point, thousands_sep) {
        return planactor.toNumber(this, decimals, dec_point, thousands_sep);
    },
    /**
     * 전화번호 format 변환
     * @memberof Number
     * @returns {string}
     */
    toPhone : function(){
        return planactor.toPhone(this);
    },
    /**
     * 파일 사이즈 byte 단위변환
     * @memberof Number
     * @returns {string}
     */
    toByteUnit : function (decimals){
        return planactor.toByteUnit(this, decimals);
    },
    /**
     * 전송속도 bps 단위변환
     * @memberof Number
     * @returns {string}
     */
    toBpsUnit : function (){
        return planactor.toBpsUnit(this);
    },
    /**
     * 숫자 절대값 반환(양수)
     * @memberof Number
     * @returns {number}
     */
    abs : function(){
        return Math.abs(this);
    },
    /**
     * 올림
     * @memberof Number
     * @returns {number}
     */
    ceil : function(){
        return Math.ceil(this);
    },
    /**
     * 반올림
     * @memberof Number
     * @returns {number}
     */
    round : function(fixed){
        return (fixed && planactor.isDigit(fixed)) ? this.toFixed(fixed) : Math.round(this);
    },
    /**
     * 내림
     * @memberof Number
     * @returns {number}
     */
    floor : function(){
        return Math.floor(this);
    }
});

if (!String.prototype.includes) {
    /**
     * includes() 메서드는 하나의 문자열이 다른 문자열에 포함되어 있는지를 결정하고, 그 결과를 true 또는 false 로 반환합니다.
     * @memberof String
     * @param {string} search 찾을 문자열
     * @param {number} start start index 이후 문자열을 찾는다.
     * @returns {boolean}
     */
    String.prototype.includes = function(search, start) {
        'use strict';
        if (typeof start !== 'number') {
            start = 0;
        }
        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}

/**
 * @class String
 */
planactor.extend(String.prototype, {
    /**
     * 문자열이 비어있을 경우 true, 나머지 false
     * @memberof String
     */
    isEmpty : function(){
        return /^\s*$/.test(this);
    },
    /**
     * 문자열이 비어있지 않을 경우 true, 나머지 false
     * @memberof String
     */
    isNotEmpty : function(){
        return !this.isEmpty();
    },
    /**
     * @memberof String
     */
    isNumber : function(){
        return planactor.isNumber(this);
    },
    /**
     * @memberof String
     */
    isDigit : function(){
        return planactor.isDigit(this);
    },
    /**
     * @memberof String
     */
    isString : function(){
        return planactor.isString(this);
    },
    /**
     * @memberof String
     */
    isLength : function(min, max){
        return planactor.isLength(this, min, max);
    },
    /**
     * @memberof String
     */
    isRange : function(min, max){
        return planactor.isRange(this, min, max);
    },
    /**
     * @memberof String
     */
    isAlpha : function(){
        return planactor.isAlpha(this);
    },
    /**
     * @memberof String
     */
    isAlnum : function(){
        return planactor.isAlnum(this);
    },
    /**
     * @memberof String
     */
    isEmail : function(){
        return planactor.isEmail(this);
    },
    /**
     * @memberof String
     */
    isHostname : function(){
        return planactor.isHostname(this);
    },
    /**
     * @memberof String
     */
    isDate : function(){
        return planactor.isDate(this);
    },
    /**
     * @memberof String
     */
    isCurrency : function(){
        return planactor.isCurrency(this);
    },
    /**
     * @memberof String
     */
    isCurrencyUSD : function(){
        return planactor.isCurrencyUSD(this);
    },
    /**
     * @memberof String
     */
    isPhone : function(){
        return planactor.isPhone(this);
    },
    /**
     * @memberof String
     */
    isMobile : function(){
        return planactor.isMobile(this);
    },
    /**
     * @memberof String
     */
    isRrn : function(){
        return planactor.isRrn(this);
    },
    /**
     * @memberof String
     */
    isBrn : function(){
        return planactor.isBrn(this);
    },
    /**
     * @memberof String
     */
    isHex : function(){
        return /^#[a-zA-Z0-9]{3,6}$/.test(this);
    },
    /**
     * @memberof String
     */
    toNumber : function (decimals, dec_point, thousands_sep) {
        return planactor.toNumber(this, decimals, dec_point, thousands_sep);
    },
    /**
     * @memberof String
     */
    toPhone : function(){
        return planactor.toPhone(this);
    },
    /**
     * @memberof String
     */
    toByteUnit : function (decimals){
        return planactor.toByteUnit(this, decimals);
    },
    /**
     * @memberof String
     */
    toBpsUnit : function (){
        return planactor.toBpsUnit(this);
    },
    /**
     * @memberof String
     */
    bytes : function(){
        return planactor.bytes(this);
    },
    /**
     * @memberof String
     */
    strcut : function(length){
        return planactor.strcut(this, length);
    },
    // 정규식 변환전 문자열 escape에 사용
    // new RegExp("("+String(search).escapeRex()+")", "ig" );
    /**
     * @memberof String
     */
    escapeRex : function(){
        return this.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
    },
    // 문자열 하일라이트 치환
    /**
     * @memberof String
     */
    highlight : function(search, replace){
        if (!replace) replace = '<span style="color:#2692fe;font-weight:bold;">$1</span>';
        var search = new RegExp("("+String(search).escapeRex()+")", "ig" );
        return this.replace(search, replace);
    },
    /**
     * @memberof String
     */
    escapeRegexp : function(){
        return this.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
    },
    /**
     * @memberof String
     */
    ext : function(){
        var last = this.lastIndexOf('.');
        if (-1 == last) return null;
        return this.substr(last+1).trim().toLowerCase();
    },
    /**
     * 구분자를 기준으로 문자열을 나눈다 (string => array)
     * 배열화된 문자열에 대해 공백은 제외되며 trim 처리
     * 'jpeg,jpg'.wsplit();
     * 'jpeg:jpg'.wsplit(':');
     */
    /**
     * @memberof String
     */
    wsplit : function(split){
        split = split || ',';
        var exts = planactor.isEmpty(this) ? '*' : this;
        exts = planactor.map(exts.split(split),function(i, ext){
            ext = ext.trim();
            var last = ext.lastIndexOf('.');
            if (-1 == last) return ext;
            return ext.substr(last+1);
        });
        return exts.compact(true);
    },
    /**
     * @memberof String
     */
    prepareReplacement : function(replacement){
        if (!planactor.isFunction(replacement)){
            return function(match, index){
                return replacement;
            };
        }
        return replacement;
    },
    /**
     * 문자열 치환
     * pgsub(' ',',')
     * pgsub(/\s+/,',')
     * pgsub(/\w+/,function(match){ return ... })
     * @memberof String
     */
    pgsub : function(regexp, replacement){
        var result = '', string = this, match, index = 0;
        replacement = this.prepareReplacement(replacement);
        if (regexp instanceof RegExp){
            regexp = new RegExp(regexp.source,(regexp.ignoreCase)?'i':'');
        }
        while(string.length > 0){
            if (match = string.match(regexp)){
                result += string.slice(0, match.index);
                result += match[0].replace(regexp,replacement(match, index));
                string = string.slice(match.index + match[0].length);
                ++index;
            } else {
                result += string, string = '';
            }
        }
        return result;
    },
    /**
     * 문자열 치환
     * var fruits = 'apple pear orange';
     * fruits.psub(' ', ', ');
     * -> 'apple, pear orange'
     * fruits.psub(' ', ', ', 1);
     * -> 'apple, pear orange'
     * fruits.psub(' ', ', ', 2);
     * -> 'apple, pear, orange'
     * fruits.psub(/\w+/, function(match){ return match[0].toLowerCase() + ',' }, 2);
     * -> 'Apple, Pear, orange'
     * @memberof String
     */
    psub : function(regexp, replacement, count){
        var index = 0, count = count || 1;
        replacement = this.prepareReplacement(replacement);
        return this.pgsub(regexp, function(match, index){
            if (--count < 0) return match[0];
            return replacement(match, index);
        });
        return this;
    },
    /**
     * rgb to hex 코드로 변경
     * "string... rgb(120, 120, 240) string...".rgbToHex()
     * object=true이면 HEX코드만 return
     * @memberof String
     * @param boolean
     * @return string
     */
    rgbToHex : function(object){
        var digits = /(.*?)(rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\))(.*)/.exec(this);

        var hex = planactor.rgbToHex(digits[3], digits[4], digits[5]);
        if (!object){
            return digits[0].replace(digits[2],hex);
        } else {
            return hex;
        }
    },
    /**
     * rgb to cmyk 코드로 변경
     * "string... rgb(120, 120, 240) string...".rgbToHex()
     * object=true이면 HEX코드만 return
     * @memberof String
     * @param boolean
     * @return string
     */
    rgbToCmyk : function(object){
        var digits = /(.*?)(rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\))(.*)/.exec(this);

        var r = parseInt(digits[3]);
        var g = parseInt(digits[4]);
        var b = parseInt(digits[5]);

        var cmyk = planactor.rgbToCmyk(r,g,b);
        if (!object){
            return digits[0].replace(digits[2],'cmyk('+cmyk+')');
        } else {
            return cmyk;
        }
    },
    /**
     * hex to rgb 코드로 변경
     * "string... #ffffff string...".hexToRgb()
     * object=true이면 RGB코드만 return
     * @memberof String
     * @param boolean
     * @return string
     */
    hexToRgb : function(object){
        var digits = /(.*?)(#[a-zA-Z0-9]{3,6})(.*)/.exec(this);

        var rgb = planactor.hexToRgb(digits[2]);
        if (!object) {
            return digits[0].replace(digits[2],'rgb('+rgb+')');
        } else {
            return rgb;
        }
    },
    /**
     * hex to cmyk
     * "string... #fff string...".hexToCmyk()
     * @memberof String
     * @param bookean
     * @return string
     */
    hexToCmyk : function(object){
        var digits = /(.*?)(#[a-zA-Z0-9]{3,6})(.*)/.exec(this);

        var cmyk = planactor.hexToCmyk(digits[2]);
        if (!object){
            return digits[0].replace(digits[2],'cmyk('+cmyk+')');
        } else {
            return cmyk;
        }
    },
    /**
     * Query 형태의 문자열을 Object형태로 변환한다.
     * @memberof String
     */
    fromQuery : function(separator){
        if (this.isEmpty()) return null;
        var paramsList = this.substring(this.indexOf('?')+1).split('#')[0].split(separator || '&'), params = {}, i, key, value, pair;

        for (i=0; i<paramsList.length; i++) {
            pair = paramsList[i].split('=');
            key = decodeURIComponent(pair[0]);
            value = (pair[1]) ? decodeURIComponent(pair[1]) : undefined;
            if (params[key]) {
                if (typeof params[key] == "string") { params[key] = [params[key]]; }
                params[key].push(value);
            } else {
                params[key] = value;
            }
        }
        return params;
    }
});

/**
 * @class Array
 */
if (!Array.prototype.map){
    /**
     * callback 반환값을 배열로 리턴
     * @memberof Array
     * @param function callback
     * @return array
     */
    Array.prototype.map = function(callback/*,bind*/){
        if (this == null) {
            throw new TypeError('Array.prototype.map: called on null or undefined');
        }
        if (!planactor.isFunction(callback)) {
            throw new TypeError('Array.prototype.map: callback is not a function');
        }
        var bind = arguments[1];
        var array = [];
        var length = this.length || 0;
        for(var i=0; i<length; ++i){
            array.push(callback.call(bind, this[i], i, this));
        }
        return array;
    };
}
if (!Array.prototype.includes){
    /**
     * 배열 내 값이 존재하는지 확인한다.
     * @memberof Array
     * @param needle
     * @return boolean
     */
    Array.prototype.includes = function(needle){
        return this.any(function(i, value){
            if (needle == value) {
                return true;
            }
            return false;
        });
    };
}
if (!Array.prototype.find){
    /**
     * 배열 내 callback 연산 후 처음 return true인 첫번째 배열값을 return
     * @memberof Array
     * @param function callback
     * - callback이 function일 경우 return true인 첫번째 배열값을 return
     * - callback이 string일 경우 해당 문자열이 든 배열의 첫번째 index를 return
     * @return number | string
     */
    Array.prototype.find = function(callback /*,bind*/){
        if (!planactor.isFunction(callback)) {
            throw new TypeError('Array.prototype.find: callback must be a function');
        }

        var length = parseInt(this.length,10) || 0;
        if (0 === length) return false;

        var bind = arguments[1];
        for(var i=0; i<length; ++i){
            if (true === callback.call(bind, this[i], i, this)){
                return this[i];
            }
        }
        return undefined;
    };
}
if (!Array.prototype.findAll){
    /**
     * callback 반환값이 true인 배열값만 리턴
     * @memberof Array
     * @param function callback
     * @return array
     */
    Array.prototype.findAll = function(callback /*,bind*/){
        if (!planactor.isFunction(callback)) {
            throw new TypeError('Array.prototype.findAll: callback must be a function');
        }
        var length = parseInt(this.length,10) || 0;

        var bind = arguments[1];
        var array = [];
        for(var i=0; i<length; ++i){
            (true === callback.call(bind, this[i], i, this)) && array.push(this[i]);
        }
        return array;
    }
}
planactor.extend(Array.prototype, {
    /**
     * 배열 복사
     * @memberof Array
     * @param void
     * @return array
     */
    clone : function(){
        return this.slice(0);
    },
    /**
     * 배열 clear
     * @memberof Array
     */
    clear : function(){
        this.length = 0;
        return this;
    },
    /**
     * 배열 내 첫번째 원소 return
     * @memberof Array
     */
    first : function(){
        return this[0];
    },
    /**
     * 배열 내 마지막 원소 return
     * @memberof Array
     */
    last : function(){
        return this[this.length-1];
    },
    /**
     * 동기식 배열순환
     * @memberof Array
     * rowset.sync(function(i, row, next){
            console.log(row);
            setTimeout(function(){
                next();
            }, 1000);
        }, function(){
            console.log('complete');
        });
     */
    sync : function(callback, complete){
        planactor.sync(this, callback, complete);
        return this;
    },
    /**
     * 배열 callback 순환
     * @memberof Array
     * @param {function} callback (value, index, 배열객체)
     * @param {object} bind callback this 객체
     * @returns {boolean}
     */
    each : function(callback/*, bind*/){
        if (!planactor.isFunction(callback)) {
            throw new TypeError('Array.prototype.each: callback must be a function');
        }
        var length = parseInt(this.length,10) || 0;
        var bind = arguments[1];
        for(var i=0; i<length; ++i){
            callback.call(bind, this[i], i, this);
        }
        return this;
    },
    /**
     * callback의 return값이 하나라도 true일 경우 즉시 true 반환, 나머지 faluse 반환
     * @memberof Array
     * @param {function} callback (value, index, 배열객체)
     * @param {object} bind callback this 객체
     * @returns {boolean}
     */
    any : function(callback/*, bind */){
        if (!planactor.isFunction(callback)) {
            throw new TypeError('Array.prototype.any: callback must be a function');
        }
        var length = parseInt(this.length,10) || 0;
        if (0 === length) return false;
        var bind = arguments[1];
        for(var i=0; i<length; ++i){
            if (true === callback.call(bind, this[i], i, this)){
                return true;
            }
        }
        return false;
    },
    /**
     * callback의 모든 return값이 true일 경우 true 반환, false return 시 즉시 중단 후 false 반환
     * @memberof Array
     * @param {function} callback (value, index, 배열객체)
     * @param {object} bind callback this 객체
     * @returns {boolean}
     */
    all : function(callback/*, bind */){
        if (!planactor.isFunction(callback)) {
            throw new TypeError('Array.prototype.all: callback must be a function');
        }
        var length = parseInt(this.length,10) || 0;
        if (0 === length) return true;
        var bind = arguments[1];
        for(var i=0; i<length; ++i){
            if (false === callback.call(bind, this[i], i, this)){
                return false;
            }
        }
        return true;
    },
    /**
     * 배열값이 null, undefined인 경우 제외하여 반환
     * @memberof Array
     * @param empty가 true일 경우 공백문자도 제외
     * @return array
     */
    compact : function(empty){
        var length = parseInt(this.length,10) || 0;
        if (0 === length) return [];
        var array = [];
        if (empty){
            for(var i=0; i<length; ++i){
                planactor.isNotEmpty(this[i]) && array.push(this[i]);
            }
        }else{
            for(var i=0; i<length; ++i){
                (null != this[i]) && array.push(this[i]);
            }
        }
        return array;
    },
    /**
     * 배열 내 최소값을 return
     * @memberof Array
     * @param void | function
     * - callback이 function인 경우 return 값이 최소인 값을 return
     * @return number | string
     */
    min : function(/* callback, bind */){
        var min;
        if (0 === arguments.length){
            this.each(function(value, index){
                if (planactor.isNumber(value)){
                    value = Number(value);
                    if (undefined === min || value < min){
                        min = value;
                    }
                }

            });
        } else {
            var callback = arguments[0], bind = arguments[1];
            if (!planactor.isFunction(callback)) {
                throw new TypeError('Array.prototype.min: callback must be a function');
            }

            var length = parseInt(this.length,10) || 0;
            if (0 === length) return undefined;
            var value;
            for(var i=0; i<length; ++i){
                value = callback.call(bind, this[i], i, this);
                if (planactor.isNumber(value)){
                    value = Number(value);
                    if (undefined === min || value < min){
                        min = value;
                    }
                }
            }
        }
        return min;
    },
    /**
     * 배열 내 최대값을 return
     * @memberof Array
     * @param void | function
     * @return number | string
     */
    max : function(/* callback, bind */){
        var max;
        if (0 === arguments.length){
            this.each(function(value, index){
                if (planactor.isNumber(value)){
                    value = Number(value);
                    if (undefined === max || value > max){
                        max = value;
                    }
                }
            });
        } else {
            var callback = arguments[0], bind = arguments[1];
            if (!planactor.isFunction(callback)) {
                throw new TypeError('Array.prototype.max: callback must be a function');
            }

            var length = parseInt(this.length,10) || 0;
            if (0 === length) return undefined;
            var value;
            for(var i=0; i<length; ++i){
                value = callback.call(bind, this[i], i, this);
                if (planactor.isNumber(value)){
                    value = Number(value);
                    if (undefined === max || value > max){
                        max = value;
                    }
                }
            }
        }
        return max;
    },
    /**
     * 배열 내 수들의 평균을 구한다.
     * @memberof Array
     */
    avg : function(/* callback, bind */){
        var sum = 0;
        var length = parseInt(this.length,10) || 0;
        if (0 === length) return undefined;

        if (0 === arguments.length){
            this.each(function(value, index){
                if (planactor.isNumber(value)){
                    sum += Number(value);
                }
            });
        } else {
            var callback = arguments[0], bind = arguments[1];
            if (!planactor.isFunction(callback)) {
                throw new TypeError('Array.prototype.avg: callback must be a function');
            }

            var value;
            for(var i=0; i < length; ++i){
                value = callback.call(bind, this[i], i, this);
                if (planactor.isNumber(value)){
                    sum += Number(value);
                }
            }
        }
        return sum/length;
    },
    /**
     * 배열 내 arguments로 설정된 값을 제거한다.
     * @memberof Array
     * @param arguments
     * @return array
     */
    without : function(){
        this.findAll(function(value, index){
            return !arguments.includes(value);
        });
    },
    /**
     * 배열 내 정규식을 통과한 값을 배열로 반환한다.
     * @memberof Array
     * @param {RegExp} 정규식
     * @return array
     */
    regexp : function(regexp){
        return this.findAll(function(value, index){
            return regexp.test(value);
        });
    },
    /**
     * callback함수로 return된 값을 기준으로 배열을 정렬한다.
     * @memberof Array
     * @param function
     * @return array
     */
    sortBy : function(/* callback, bind */){
        var object = this.clone();
        if (0 === arguments.length){
            object.sort();
        } else {
            var sort_array = [];
            var callback = arguments[0], bind = arguments[1];
            if (!planactor.isFunction(callback)) {
                throw new TypeError('Array.prototype.sortBy: callback must be a function');
            }
            object.each(function(value, index){
                sort_array.push(callback.call(this, value, index, object));
            }, bind);
            var length = this.length, temp;
            for(var i=0; i<length-1; ++i){
                for(var j=i+1; j<length; ++j){
                    if (sort_array[i] > sort_array[j]){
                        temp = sort_array[i], sort_array[i] = sort_array[j], sort_array[j] = temp;
                        temp = object[i], object[i] = object[j], object[j] = temp;
                    }
                }
            }
        }
        return object;
    },
    /**
     * callback함수로 return된 값을 기준으로 배열을 역순정렬한다.
     * @memberof Array
     * @param function
     * @return array
     */
    reverseBy : function(callback){
        var object = this.clone();
        if (0 === arguments.length){
            object.reverse();
        } else {
            var sort_array = [];
            var callback = arguments[0], bind = arguments[1];
            if (!planactor.isFunction(callback)) {
                throw new TypeError('Array.prototype.reverseBy: callback must be a function');
            }
            object.each(function(value, index){
                sort_array.push(callback.call(this, value, index, object));
            },bind);
            var length = this.length, temp;
            for(var i=0; i<length-1; ++i){
                for(var j=i+1; j<length; ++j){
                    if (sort_array[i] < sort_array[j]){
                        temp = sort_array[i], sort_array[i] = sort_array[j], sort_array[j] = temp;
                        temp = object[i], object[i] = object[j], object[j] = temp;
                    }
                }
            }
        }
        return object;
    }
});

/**
 * @class Date
 */
(function(){
    if (Date && Date.prototype){
        Date._lunarMonthTable = [
             [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2],
             [2, 1, 2, 5, 2, 1, 2, 1, 2, 1, 2, 1],
             [1, 2, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2], /* 1801 */
             [1, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2, 1],
             [2, 3, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2],
             [1, 2, 1, 2, 1, 3, 2, 1, 2, 2, 2, 1],
             [2, 2, 1, 2, 1, 1, 1, 2, 1, 2, 2, 1],
             [2, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
             [1, 2, 2, 1, 5, 2, 1, 2, 1, 1, 2, 1],
             [2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1, 2],
             [1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2],
             [1, 1, 5, 2, 1, 2, 2, 1, 2, 2, 1, 2], /* 1811 */
             [1, 1, 2, 1, 2, 1, 2, 1, 2, 2, 2, 1],
             [2, 1, 2, 1, 1, 1, 2, 1, 2, 2, 2, 1],
             [2, 5, 2, 1, 1, 1, 2, 1, 2, 2, 1, 2],
             [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 2, 1, 2, 1, 5, 1, 2, 1, 2, 1, 2],
             [2, 1, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1],
             [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 1, 2],
             [1, 2, 1, 5, 2, 2, 1, 2, 2, 1, 2, 1],
             [1, 2, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
             [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2], /* 1821 */
             [2, 1, 5, 1, 1, 2, 1, 2, 2, 1, 2, 2],
             [2, 1, 2, 1, 1, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 2, 1, 4, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 1, 2, 2, 4, 1, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 1, 2, 3, 2, 1, 2, 2, 1, 2, 2, 2],
             [1, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2], /* 1831 */
             [1, 2, 1, 2, 1, 1, 2, 1, 5, 2, 2, 2],
             [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
             [1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [1, 2, 2, 1, 2, 5, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
             [1, 2, 1, 5, 1, 2, 2, 1, 2, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
             [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 4, 1, 1, 2, 1, 2, 1, 2, 2, 1],   /* 1841 */
             [2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 1],
             [2, 2, 2, 1, 2, 1, 4, 1, 2, 1, 2, 1],
             [2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 5, 2, 1, 2, 2, 1, 2, 1],
             [2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
             [2, 1, 2, 3, 2, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
             [2, 2, 1, 2, 1, 1, 2, 3, 2, 1, 2, 2],   /* 1851 */
             [2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 1, 2],
             [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 5, 2, 1, 2, 1, 2],
             [1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1],
             [2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
             [1, 2, 1, 1, 5, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
             [2, 1, 6, 1, 1, 2, 1, 1, 2, 1, 2, 2],
             [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2],   /* 1861 */
             [2, 1, 2, 1, 2, 2, 1, 5, 2, 1, 1, 2],
             [1, 2, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
             [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 1, 2, 4, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2],
             [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
             [1, 2, 2, 3, 2, 1, 1, 2, 1, 2, 2, 1],
             [2, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 2, 2, 1, 2, 1, 2, 1, 1, 5, 2, 1],
             [2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1, 2],   /* 1871 */
             [1, 2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
             [1, 1, 2, 1, 2, 4, 2, 1, 2, 2, 1, 2],
             [1, 1, 2, 1, 2, 1, 2, 1, 2, 2, 2, 1],
             [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
             [2, 2, 1, 1, 5, 1, 2, 1, 2, 2, 1, 2],
             [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 2, 4, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 1, 2],
             [1, 2, 1, 2, 1, 2, 5, 2, 2, 1, 2, 1],   /* 1881 */
             [1, 2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
             [2, 1, 1, 2, 3, 2, 1, 2, 2, 1, 2, 2],
             [2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 2, 1, 5, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
             [1, 5, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],   /* 1891 */
             [1, 1, 2, 1, 1, 5, 2, 2, 1, 2, 2, 2],
             [1, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 5, 1, 2, 1, 2, 1, 2, 1],
             [2, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
             [2, 1, 5, 2, 2, 1, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 5, 2, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],   /* 1901 */
             [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 2, 3, 2, 1, 1, 2, 2, 1, 2],
             [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1],
             [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
             [1, 2, 2, 4, 1, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
             [2, 1, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
             [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
             [2, 1, 2, 1, 1, 5, 1, 2, 2, 1, 2, 2],   /* 1911 */
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
             [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
             [2, 2, 1, 2, 5, 1, 2, 1, 2, 1, 1, 2],
             [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
             [2, 3, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1],
             [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 2, 1, 1, 2, 1, 5, 2, 1, 2, 2, 2],
             [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],   /* 1921 */
             [2, 1, 2, 2, 3, 2, 1, 1, 2, 1, 2, 2],
             [1, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
             [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
             [2, 1, 2, 5, 2, 1, 2, 2, 1, 2, 1, 2],
             [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 5, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2],
             [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
             [1, 2, 2, 1, 1, 5, 1, 2, 1, 2, 2, 1],
             [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],   /* 1931 */
             [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
             [1, 2, 2, 1, 6, 1, 2, 1, 2, 1, 1, 2],
             [1, 2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
             [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 4, 1, 1, 2, 2, 1, 2, 2, 2, 1],
             [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
             [2, 2, 1, 1, 2, 1, 4, 1, 2, 2, 1, 2],
             [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 2, 1, 2, 2, 4, 1, 1, 2, 1, 2, 1],   /* 1941 */
             [2, 1, 2, 2, 1, 2, 2, 1, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 4, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
             [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
             [2, 5, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 1, 2, 2, 1, 2, 3, 2, 1, 2, 1, 2],
             [1, 2, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],   /* 1951 */
             [1, 2, 1, 2, 4, 1, 2, 2, 1, 2, 1, 2],
             [1, 2, 1, 1, 2, 2, 1, 2, 2, 1, 2, 2],
             [1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
             [2, 1, 4, 1, 1, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 2, 1, 1, 5, 2, 1, 2, 2],
             [1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],   /* 1961 */
             [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2, 1],
             [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
             [1, 2, 5, 2, 1, 1, 2, 1, 1, 2, 2, 1],
             [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 2, 1, 5, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
             [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
             [1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1, 2],   /* 1971 */
             [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
             [2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1, 2],
             [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
             [2, 2, 1, 2, 1, 2, 1, 5, 1, 2, 1, 2],
             [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1],
             [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1],
             [2, 1, 1, 2, 1, 6, 1, 2, 2, 1, 2, 1],
             [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],   /* 1981 */
             [2, 1, 2, 3, 2, 1, 1, 2, 1, 2, 2, 2],
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
             [2, 1, 2, 2, 1, 1, 2, 1, 1, 5, 2, 2],
             [1, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
             [1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
             [2, 1, 2, 1, 2, 5, 2, 2, 1, 2, 1, 2],
             [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 1, 2, 1, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 1, 5, 1, 2, 2, 1, 2, 2, 2],
             [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],   /* 1991 */
             [1, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
             [1, 2, 5, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
             [1, 2, 2, 1, 2, 1, 2, 5, 2, 1, 1, 2],
             [1, 2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 1],
             [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 1, 2, 3, 2, 2, 1, 2, 2, 2, 1],
             [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
             [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1],
             [2, 2, 1, 5, 2, 1, 1, 2, 1, 2, 1, 2],   /* 2001 */
             [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2],
             [1, 5, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 2, 1, 5, 2, 2, 1, 2, 2],
             [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
             [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
             [2, 2, 1, 1, 5, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],   /* 2011 */
             [2, 1, 2, 5, 2, 2, 1, 1, 2, 1, 2, 1],
             [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 1, 2, 5, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2, 1],
             [2, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 2, 1, 2, 1, 4, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2],
             [2, 1, 2, 5, 2, 1, 1, 2, 1, 2, 1, 2],
             [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],   /* 2021 */
             [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
             [1, 5, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1],
             [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1],
             [2, 2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1],
             [2, 2, 1, 2, 2, 1, 1, 2, 1, 1, 2, 2],
             [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
             [2, 1, 5, 2, 1, 2, 2, 1, 2, 1, 2, 1],   /* 2031 */
             [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 5, 2],
             [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2],
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
             [2, 2, 1, 2, 1, 4, 1, 1, 2, 2, 1, 2],
             [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
             [2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1],
             [2, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1, 1],
             [2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2, 1],
             [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],   /* 2041 */
             [1, 5, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
             [2, 1, 2, 1, 1, 2, 3, 2, 1, 2, 2, 2],
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
             [2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
             [2, 1, 2, 2, 4, 1, 2, 1, 1, 2, 1, 2],
             [1, 2, 2, 1, 2, 2, 1, 2, 1, 1, 2, 1],
             [2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1],
             [1, 2, 4, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2],   /* 2051 */
             [1, 2, 1, 1, 2, 1, 1, 5, 2, 2, 2, 2],
             [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
             [1, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
             [1, 2, 2, 1, 2, 4, 1, 1, 2, 1, 2, 1],
             [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
             [1, 2, 2, 1, 2, 1, 2, 2, 1, 1, 2, 1],
             [2, 1, 2, 4, 2, 1, 2, 1, 2, 2, 1, 1],
             [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2, 1],
             [2, 2, 3, 2, 1, 1, 2, 1, 2, 2, 2, 1],   /* 2061 */
             [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1],
             [2, 2, 1, 2, 1, 2, 3, 2, 1, 2, 1, 2],
             [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2],
             [1, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 1, 2, 2, 1, 2, 2, 1, 2],
             [1, 2, 1, 5, 1, 2, 1, 2, 2, 2, 1, 2],
             [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
             [2, 1, 2, 1, 2, 1, 1, 5, 2, 1, 2, 2],   /* 2071 */
             [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
             [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
             [2, 1, 2, 2, 1, 5, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1],
             [2, 1, 2, 3, 2, 1, 2, 2, 2, 1, 2, 1],
             [2, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
             [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
             [2, 1, 5, 2, 1, 1, 2, 1, 2, 1, 2, 2],
             [1, 2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2],   /* 2081 */
             [1, 2, 2, 2, 1, 2, 3, 2, 1, 1, 2, 2],
             [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
             [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
             [1, 2, 1, 1, 6, 1, 2, 2, 1, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
             [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
             [1, 2, 1, 5, 1, 2, 1, 1, 2, 2, 2, 1],
             [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1],
             [2, 2, 2, 1, 2, 1, 1, 5, 1, 2, 2, 1],
             [2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1],   /* 2091 */
             [2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
             [1, 2, 2, 1, 2, 4, 2, 1, 2, 1, 2, 1],
             [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
             [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
             [2, 1, 2, 3, 2, 1, 1, 2, 2, 2, 1, 2],
             [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
             [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
             [2, 5, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2],
             [2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1],
             [2, 2, 1, 2, 2, 1, 5, 2, 1, 1, 2, 1]
             ];
        Date._holidayEveryYear = [
              {'title': '신정',    'month': 1, 'day': 1, 'lunar': false, 'holiday': true},
              {'title': '',       'month': 1, 'day': 0, 'lunar': true, 'holiday': true},
              {'title': '설날',     'month': 1, 'day': 1, 'lunar': true, 'holiday': true},
              {'title': '',       'month': 1, 'day': 2, 'lunar': true, 'holiday': true},
              {'title': '3.1절',   'month': 3, 'day': 1, 'lunar': false, 'holiday': true},
              {'title': '식목일',    'month': 4, 'day': 5, 'lunar': false, 'holiday': true},
              {'title': '석가탄신일',  'month': 4, 'day': 8, 'lunar': true, 'holiday': true},
              {'title': '어린이날',   'month': 5, 'day': 5, 'lunar': false, 'holiday': true},
              {'title': '현충일',    'month': 6, 'day': 6, 'lunar': false, 'holiday': true},
              {'title': '제헌절',    'month': 7, 'day': 17, 'lunar': false, 'holiday': false},
              {'title': '광복절',    'month': 8, 'day': 15, 'lunar': false, 'holiday': true},
              {'title': '',       'month': 8, 'day': 14, 'lunar': true, 'holiday': true},
              {'title': '추석',     'month': 8, 'day': 15, 'lunar': true, 'holiday': true},
              {'title': '',       'month': 8, 'day': 16, 'lunar': true, 'holiday': true},
              {'title': '개천절',   'month': 10, 'day': 3, 'lunar': false, 'holiday': true},
              {'title': '성탄절',   'month': 12, 'day': 25, 'lunar': false, 'holiday': true}
              ];
        Date._holidayEveryWeek = [
              {'title':'일요일', 'weekday':0, 'holiday':true}
              ];
        Date._holidayEveryMonth = [];
        Date._holidaySpecifiedDate = [
              {'title': '14대 국회의원 선거일', 'year': 1992, 'month': 3, 'day': 24, 'holiday': true},
              {'title': '15대 국회의원 선거일', 'year': 1996, 'month': 4, 'day': 11, 'holiday': true},
              {'title': '16대 국회의원 선거일', 'year': 2000, 'month': 4, 'day': 13, 'holiday': true},
              {'title': '17대 국회의원 선거일', 'year': 2004, 'month': 4, 'day': 15, 'holiday': true},
              {'title': '18대 국회의원 선거일', 'year': 2008, 'month': 4, 'day': 9, 'holiday': true},
              {'title': '19대 국회의원 선거일', 'year': 2012, 'month': 4, 'day': 11, 'holiday': true},
              {'title': '20대 국회의원 선거일', 'year': 2016, 'month': 4, 'day': 13, 'holiday': true},
              {'title': '13대 대통령 선거일', 'year': 1987, 'month': 12, 'day': 16, 'holiday': true},   // 노태우
              {'title': '14대 대통령 선거일', 'year': 1992, 'month': 12, 'day': 18, 'holiday': true},   // 김영삼
              {'title': '15대 대통령 선거일', 'year': 1997, 'month': 12, 'day': 18, 'holiday': true},   // 김대중
              {'title': '16대 대통령 선거일', 'year': 2002, 'month': 12, 'day': 19, 'holiday': true},   // 노무현
              {'title': '17대 대통령 선거일', 'year': 2007, 'month': 12, 'day': 19, 'holiday': true},   // 이명박
              {'title': '18대 대통령 선거일', 'year': 2012, 'month': 12, 'day': 19, 'holiday': true},   // 박근혜
              {'title': '19대 대통령 선거일', 'year': 2017, 'month': 12, 'day': 20, 'holiday': true}
              ];
        Date._ganji = {
            '_ganji' : {
                'gan'   : ['庚','辛','壬','癸','甲','乙','丙','丁','戊','己'],
                'korGan': ['경','신','임','계','갑','을','병','정','무','기'],
                'ji'    : ['申','酉','戌','亥','子','丑','寅','卯','辰','巳','午','未'],
                'korJi' : ['신','유','술','해', '자','축','인','묘','진','사','오','미'],
                'tti'   : ['원숭이','닭','개','돼지','쥐','소','호랑이','토끼','용','뱀','말','양']
            }
        };
        /**
         * @memberof Date
         */
        Date.prototype.iso8601Week = function (){
            var e,i=new Date(this.getTime());
            return i.setDate(i.getDate()+4-(i.getDay()||7)),e=i.getTime(),i.setMonth(0),i.setDate(1),Math.floor(Math.round((e-i)/864e5)/7)+1;
        };
        planactor.extend(Date.prototype, {
            /**
             * @memberof Date
             */
            getWeek : Date.prototype.iso8601Week,
            /**
             * @memberof Date
             */
            getISOWeek : Date.prototype.iso8601Week,
            /**
             * @memberof Date
             */
            resetHoliday : function(){
                var year = this.getFullYear();
                this._holidaySpecifiedDate = Date._holidaySpecifiedDate;
                this._holidayEveryWeek = Date._holidayEveryWeek;
                this._holidayEveryMonth = Date._holidayEveryMonth;
                this._holidayEveryYear = Date._holidayEveryYear;
                /* set the day before 설날 */
                if (0 == this._holidayEveryYear[1].day){
                    if (Date._lunarMonthTable[year - 1 - 1799][11] == 1) this._holidayEveryYear[1].day = 29;
                    else if (Date._lunarMonthTable[year - 1 - 1799][11] == 2) this._holidayEveryYear[1].day = 30;
                }
            },
            /**
             * @memberof Date
             */
            addHolidayEveryWeek : function(data){
                if (null == this._holidayEveryWeek) this.resetHoliday();
                data = planactor.extend({
                    'title'     : '',
                    'weekday'   : 0,
                    'holiday'   : true
                }, data);
                this._holidayEveryWeek.push(data);
                return this;
            },
            /**
             * @memberof Date
             */
            addHolidayEveryMonth : function(data){
                if (null == this._holidayEveryMonth) this.resetHoliday();
                data = planactor.extend({
                    'title'     : '',
                    'parityweek': 1,
                    'weekday'   : 0,
                    'holiday'   : true
                }, data);
                this._holidayEveryMonth.push(data);
                return this;
            },
            /**
             * @memberof Date
             */
            addHolidayEveryYear : function(data){
                if (null == this._holidayEveryYear) this.resetHoliday();
                data = planactor.extend({
                    'title'     : '',
                    'month'     : null,
                    'day'       : null,
                    'lunar'     : false,
                    'holiday'   : false
                }, data);
                this._holidayEveryYear.push(data);
                return this;
            },
            /**
             * @memberof Date
             */
            addHolidaySpecifiedDate : function(data){
                if (null == this._holidaySpecifiedDate) this.resetHoliday();
                data = planactor.extend({
                    'title'     : '',
                    'year'      : null,
                    'month'     : null,
                    'day'       : null,
                    'holiday'   : true
                }, data);
                this._holidaySpecifiedDate.push(data);
                return this;
            },
            /**
             * @memberof Date
             */
            findHolidayEveryWeek : function(){
                if (null == this._holidayEveryWeek) this.resetHoliday();
                var weekday = this.getDay();
                return this._holidayEveryWeek.findAll(function(memorial){
                    if (weekday == memorial.weekday) return true;
                    return false;
                });
            },
            /**
             * @memberof Date
             */
            findHolidayEveryMonth : function(){
                if (null == this._holidayEveryMonth) this.resetHoliday();
                var weekday = this.getDay();
                var parityweek = this.iso8601Week()%2;
                return this._holidayEveryMonth.findAll(function(memorial){
                    if (parityweek == memorial.parityweek && weekday == memorial.weekday) return true;
                    return false;
                });
            },
            /**
             * @memberof Date
             */
            findHolidayEveryYear : function(){
                var self = this;
                if (null == this._holidayEveryYear) this.resetHoliday();
                var year = this.getFullYear();
                var month = this.getMonth()+1;
                var day = this.getDate();

                return this._holidayEveryYear.findAll(function(memorial){
                    if (false === memorial.lunar && month == memorial.month && day == memorial.day){
                        return true;
                    }
                    var lunardate = self.toLunar();
                    if (1 == lunardate.leapMonth){
                        if (4 == lunardate.month && 8 == lunardate.day) return false;
                        if (8 == lunardate.month && 13 < lunardate.day && 17 > lunardate.day) return false;
                    }
                    if (true === memorial.lunar && lunardate.month == memorial.month && lunardate.day == memorial.day && 0 == lunardate.leapMonth){
                        return true;
                    }
                    return false;
                });
            },
            /**
             * @memberof Date
             */
            findHolidaySpecifiedDate : function(){
                if (null == this._holidaySpecifiedDate) this.resetHoliday();
                var year = this.getFullYear();
                var month = this.getMonth()+1;
                var day = this.getDate();

                return this._holidaySpecifiedDate.findAll(function(memorial){
                    if (year == memorial.year && month == memorial.month && day == memorial.day) return true;
                    return false;
                });
            },
            /**
             * @memberof Date
             */
            findHolidays : function(){
                var _holidays = {'everyWeek':null,'everyMonth':null,'everyYear':null,'specifiedDate':null};
                _holidays.specifiedDate = this.findHolidaySpecifiedDate();
                _holidays.everyYear = this.findHolidayEveryYear();
                _holidays.everyMonth = this.findHolidayEveryMonth();
                _holidays.everyWeek = this.findHolidayEveryWeek();
                return _holidays;
            },
            /**
             * @memberof Date
             */
            isHoliday : function(){
                var _holidays = this.findHolidays();
                // 특정일
                if (planactor.isArray(_holidays.specifiedDate) && _holidays.specifiedDate.length > 0){
                    var is = _holidays.specifiedDate.any(function(memorial){
                        if (true === memorial.holiday) return true;
                        return false;
                    });
                    if (true === is) return true;
                }
                // 매년
                if (planactor.isArray(_holidays.everyYear) && _holidays.everyYear.length > 0){
                    var is = _holidays.everyYear.any(function(memorial){
                        if (true === memorial.holiday) return true;
                        return false;
                    });
                    if (true === is) return true;
                }
                // 매월
                if (planactor.isArray(_holidays.everyMonth) && _holidays.everyMonth.length > 0){
                    var is = _holidays.everyMonth.any(function(memorial){
                        if (true === memorial.holiday) return true;
                        return false;
                    });
                    if (true === is) return true;
                }
                // 매주
                if (planactor.isArray(_holidays.everyWeek) && _holidays.everyWeek.length > 0){
                    var is = _holidays.everyWeek.any(function(memorial){
                        if (true === memorial.holiday) return true;
                        return false;
                    });
                    if (true === is) return true;
                }
                return false;
            },
            /**
             * 휴일을 제외한 day 이후 일자 반환
             * 0==day 당일 (당일이 휴일일 경우 이후 첫번째 workday datetime을 반환한다.
             * @memberof Date
             */
            getAfterWorkDay : function(day){
                var clonedate = this.clone();
                // 당일
                if (0 == day){
                    // 평일
                    if (false === clonedate.isHoliday()) return clonedate;
                    else day = 1;
                }
                while(0 < day){
                    clonedate.add({days:1});
                    // 평일
                    (false === clonedate.isHoliday()) && --day;
                }
                return clonedate;
            },
            /**
             * @memberof Date
             */
            getGangiYear : function(){
                // 음력변경
                var lunarDatetime = this.toLunar();
                var lunyear   = lunarDatetime.year;

                var ganindex = String(lunyear).substr(-1);
                var jiindex = Math.floor(lunyear%12);
                var gan    = Date._ganji['gan'][ganindex];
                var korGan = Date._ganji['korGan'][ganindex];
                var ji     = Date._ganji['ji'][jiindex];
                var korJi  = Date._ganji['korJi'][jiindex];
                var tti    = Date._ganji['tti'][jiindex];

                return {
                    'ganji'     : gan+ji,
                    'korGanji'  : korGan+korJi,
                    'gan'       : gan,
                    'ji'        : ji,
                    'korGan'    : korGan,
                    'korJi'     : korJi,
                    'tti'       : tti
                };
            },
            /**
             * 양력 -> 음력
             * @memberof Date
             */
            toLunar : function(){
                return this.lunarCalc(this.getFullYear(), this.getMonth()+1, this.getDate(), true, false);
            },
            /* 양력 <-> 음력 변환 함수
             * type : 1 - 양력 -> 음력
             *        2 - 음력 -> 양력
             * leapMonth : 0 - 평달
             *             1 - 윤달 (type = 2 일때만 유효)
             * @memberof Date
             */
            lunarCalc : function(year, month, day, type, leapMonth){
                var solYear, solMonth, solDay;
                var lunYear, lunMonth, lunDay;
                var lunLeapMonth, lunMonthDay;
                var i, lunIndex;

                var solMonthDay = [31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

                /* range check */
                if (year < 1800 || year > 2101)
                {
                    return false;
                    alert('1800년부터 2101년까지만 지원합니다');
                }
                /* 속도 개선을 위해 기준 일자를 여러개로 한다 */
                if (year >= 2080)
                {
                    /* 기준일자 양력 2080년 1월 1일 (음력 2079년 12월 10일) */
                    solYear = 2080;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 2079;
                    lunMonth = 12;
                    lunDay = 10;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 2080 년 2월 28일 */
                    lunMonthDay = 30; /* 2079년 12월 */
                }
                else if (year >= 2060)
                {
                    /* 기준일자 양력 2060년 1월 1일 (음력 2059년 11월 28일) */
                    solYear = 2060;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 2059;
                    lunMonth = 11;
                    lunDay = 28;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 2060 년 2월 28일 */
                    lunMonthDay = 30; /* 2059년 11월 */
                }
                else if (year >= 2040)
                {
                    /* 기준일자 양력 2040년 1월 1일 (음력 2039년 11월 17일) */
                    solYear = 2040;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 2039;
                    lunMonth = 11;
                    lunDay = 17;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 2040 년 2월 28일 */
                    lunMonthDay = 29; /* 2039년 11월 */
                }
                else if (year >= 2020)
                {
                    /* 기준일자 양력 2020년 1월 1일 (음력 2019년 12월 7일) */
                    solYear = 2020;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 2019;
                    lunMonth = 12;
                    lunDay = 7;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 2020 년 2월 28일 */
                    lunMonthDay = 30; /* 2019년 12월 */
                }
                else if (year >= 2000)
                {
                    /* 기준일자 양력 2000년 1월 1일 (음력 1999년 11월 25일) */
                    solYear = 2000;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1999;
                    lunMonth = 11;
                    lunDay = 25;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 2000 년 2월 28일 */
                    lunMonthDay = 30; /* 1999년 11월 */
                }
                else if (year >= 1980)
                {
                    /* 기준일자 양력 1980년 1월 1일 (음력 1979년 11월 14일) */
                    solYear = 1980;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1979;
                    lunMonth = 11;
                    lunDay = 14;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 1980 년 2월 28일 */
                    lunMonthDay = 30; /* 1979년 11월 */
                }
                else if (year >= 1960)
                {
                    /* 기준일자 양력 1960년 1월 1일 (음력 1959년 12월 3일) */
                    solYear = 1960;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1959;
                    lunMonth = 12;
                    lunDay = 3;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 1960 년 2월 28일 */
                    lunMonthDay = 29; /* 1959년 12월 */
                }
                else if (year >= 1940)
                {
                    /* 기준일자 양력 1940년 1월 1일 (음력 1939년 11월 22일) */
                    solYear = 1940;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1939;
                    lunMonth = 11;
                    lunDay = 22;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 1940 년 2월 28일 */
                    lunMonthDay = 29; /* 1939년 11월 */
                }
                else if (year >= 1920)
                {
                    /* 기준일자 양력 1920년 1월 1일 (음력 1919년 11월 11일) */
                    solYear = 1920;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1919;
                    lunMonth = 11;
                    lunDay = 11;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 1920 년 2월 28일 */
                    lunMonthDay = 30; /* 1919년 11월 */
                }
                else if (year >= 1900)
                {
                    /* 기준일자 양력 1900년 1월 1일 (음력 1899년 12월 1일) */
                    solYear = 1900;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1899;
                    lunMonth = 12;
                    lunDay = 1;
                    lunLeapMonth = false;

                    solMonthDay[1] = 28; /* 1900 년 2월 28일 */
                    lunMonthDay = 30; /* 1899년 12월 */
                }
                else if (year >= 1880)
                {
                    /* 기준일자 양력 1880년 1월 1일 (음력 1879년 11월 20일) */
                    solYear = 1880;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1879;
                    lunMonth = 11;
                    lunDay = 20;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 1880 년 2월 28일 */
                    lunMonthDay = 30; /* 1879년 11월 */
                }
                else if (year >= 1860)
                {
                    /* 기준일자 양력 1860년 1월 1일 (음력 1859년 12월 9일) */
                    solYear = 1860;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1859;
                    lunMonth = 12;
                    lunDay = 9;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 1860 년 2월 28일 */
                    lunMonthDay = 30; /* 1859년 12월 */
                }
                else if (year >= 1840)
                {
                    /* 기준일자 양력 1840년 1월 1일 (음력 1839년 11월 27일) */
                    solYear = 1840;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1839;
                    lunMonth = 11;
                    lunDay = 27;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 1840 년 2월 28일 */
                    lunMonthDay = 30; /* 1839년 11월 */
                }
                else if (year >= 1820)
                {
                    /* 기준일자 양력 1820년 1월 1일 (음력 1819년 11월 16일) */
                    solYear = 1820;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1819;
                    lunMonth = 11;
                    lunDay = 16;
                    lunLeapMonth = false;

                    solMonthDay[1] = 29; /* 1820 년 2월 28일 */
                    lunMonthDay = 30; /* 1819년 11월 */
                }
                else if (year >= 1800)
                {
                    /* 기준일자 양력 1800년 1월 1일 (음력 1799년 12월 7일) */
                    solYear = 1800;
                    solMonth = 1;
                    solDay = 1;
                    lunYear = 1799;
                    lunMonth = 12;
                    lunDay = 7;
                    lunLeapMonth = false;

                    solMonthDay[1] = 28; /* 1800 년 2월 28일 */
                    lunMonthDay = 30; /* 1799년 12월 */
                }
                lunIndex = lunYear - 1799;

                while (true)
                {
                    // 양력 -> 음력
                    if (type == true && year == solYear && month == solMonth && day == solDay)
                    {
                        return {
                            'year'  : lunYear,
                            'month' : lunMonth,
                            'day'   : lunDay,
                            'leapMonth' : lunLeapMonth
                        };
                    }
                    // 음력 -> 양력
                    else if (type == false && year == lunYear && month == lunMonth && day == lunDay && leapMonth == lunLeapMonth)
                    {
                        return {
                            'year'  : solYear,
                            'month' : solMonth,
                            'day'   : solDay,
                            'leapMonth' : 0
                        };
                    }

                    /* add a day of solar calendar */
                    if (solMonth == 12 && solDay == 31)
                    {
                        solYear++;
                        solMonth = 1;
                        solDay = 1;

                        /* set monthDay of Feb */
                        if (solYear % 400 == 0) solMonthDay[1] = 29;
                        else if (solYear % 100 == 0) solMonthDay[1] = 28;
                        else if (solYear % 4 == 0) solMonthDay[1] = 29;
                        else solMonthDay[1] = 28;
                    }
                    else if (solMonthDay[solMonth - 1] == solDay)
                    {
                        solMonth++;
                        solDay = 1;
                    }
                    else
                        solDay++;

                    /* add a day of lunar calendar */
                    if (lunMonth == 12 && ((Date._lunarMonthTable[lunIndex][lunMonth - 1] == 1 && lunDay == 29) || (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 2 && lunDay == 30)))
                    {
                        lunYear++;
                        lunMonth = 1;
                        lunDay = 1;

                        if (lunYear > 2101) {
                            return false;
                            alert("입력하신 날 또는 달은 없습니다. 다시 입력하시기 바랍니다.");
                            break;
                        }

                        lunIndex = lunYear - 1799;

                        if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 1) lunMonthDay = 29;
                        else if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 2) lunMonthDay = 30;
                    }
                    else if (lunDay == lunMonthDay){
                        if (Date._lunarMonthTable[lunIndex][lunMonth - 1] >= 3 && lunLeapMonth == false){
                            lunDay = 1;
                            lunLeapMonth = true;
                        }
                        else
                        {
                            lunMonth++;
                            lunDay = 1;
                            lunLeapMonth = false;
                        }

                        if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 1) lunMonthDay = 29;
                        else if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 2) lunMonthDay = 30;
                        else if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 3) lunMonthDay = 29;
                        else if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 4 && lunLeapMonth == false) lunMonthDay = 29;
                        else if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 4 && lunLeapMonth == true) lunMonthDay = 30;
                        else if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 5 && lunLeapMonth == false) lunMonthDay = 30;
                        else if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 5 && lunLeapMonth == true) lunMonthDay = 29;
                        else if (Date._lunarMonthTable[lunIndex][lunMonth - 1] == 6) lunMonthDay = 30;
                    }
                    else lunDay++;
                }
            }
        });
    }
})();

/**
 * 비동기 로직 동기화 처리 함수 (비동기적 스크립트에 대해 순차적 실행을 원할 때 사용)
 * @class
 * @memberof planactor
 * @param {object} options
 * @example
var $sync = new planactor.syncQueue({
    'multi' : 1,
    'create' : function(){
        console.log('create');
    },
    'complete' : function(){
        console.log('complete');
    }
});
for(var i=0; i<100; ++i){
    $sync.add(function(callback, index){
        console.log(index);
        callback();
    });
    // or
    $sync.add(planactor.proxy(function(i, callback, index)){
        callback();
    },null,i))
}
$sync.start();
 */
planactor.syncQueue = function(options){
    var self = this;
    this.queue = [];
    this.undoQueue = [];
    this.index = 0;
    this.runCount = 0;
    this.options = planactor.extend({
        'create'    : function(){},
        'complete'  : function(){},
        'error'     : function(){},
        'multi'     : 1
    }, options);
    this._stop = false;
};
planactor.syncQueue.prototype = {
    /**
     * 비동기 로직
     * @param {Object} func 함수 또는 배열함수
     */
    add : function(func){
        if (planactor.isFunction(func)) this.queue.push(func);
        else if (planactor.isArray(func)) this.queue.concat(func);
        // else this.options.error(func);
        else throw Error('planactor.sync.add(func) param error!');
        return this;
    },
    /**
     *
     * @param options
     * @returns {planactor.syncQueue}
     */
    setOption : function(options){
        this.options = planactor.extend({}, this.options, options);
        return this;
    },
    callback : function(){
        (this.runCount > 0) && --this.runCount;
        if (this.queue.length > 0 && (false === this._stop)){
            ++this.runCount;
            var func = this.queue.shift();
            if (planactor.isFunction(func)){
                this.undoQueue.push(func);
                func(this.callback.bind(this), this.index++);
            }
        }
        // 처리할 함수가 없을 경우 complete 호출
        else{
            if((0 == this.runCount && 0 == this.queue.length) || true === this._abort){
                this.options.complete();
                this.clearQueue();
            }
        }
        return this;
    },
    /**
     * @param {boolean} restart
     * @returns {planactor.syncQueue}
     */
    start : function(restart){
        this._stop = false;
        if (this.queue.length > 0){
            (!restart) && this.options.create();
            for(var i=0; (i < this.options.multi) && (this.queue.length > 0) && (false === this._stop); ++i){
                ++this.runCount;
                var func = this.queue.shift();
                if (planactor.isFunction(func)){
                    this.undoQueue.push(func);
                    func(this.callback.bind(this), this.index++);
                }
            }
        }
        return this;
    },
    /**
     * @returns {planactor.syncQueue}
     */
    restart : function(){
        return this.start(true);
    },
    /**
     * @returns {planactor.syncQueue}
     */
    abort : function(){
        this._stop = true;
        this._abort = true;
        return this;
    },
    /**
     * @returns {planactor.syncQueue}
     */
    stop : function(){
        this._stop = true;
        return this;
    },
    /**
     * @returns {planactor.syncQueue}
     */
    undo : function(){
        var count = this.runCount || 1;
        for(var i=0; i<count; ++i){
            this.queue.unshift(this.undoQueue.pop());
            --this.index;
        }
        return this;
    },
    /**
     * @returns {number}
     */
    queueCount : function(){
        return this.queue.length;
    },
    /**
     * @returns {planactor.syncQueue}
     */
    clearQueue : function(){
        this.queue.length = 0;
        this.undoQueue.length = 0;
        return this;
    },
    /**
     * @returns {planactor.syncQueue}
     */
    reset : function(){
        this.clearQueue();
        this.index = 0;
        this.runCount = 0;
        // this.options = {
        // 'create'    : function(){},
        // 'complete'  : function(){},
        // 'multi'     : 1
        // };
        return this;
    }
};

planactor.translate = function(){};

(function(planactor){
    if (planactor.isUseFileApi){
        /**
         * FileList 확장
         */
        if (FileList && FileList.prototype){
            /**
             * @class
             */
            planactor.extend(FileList.prototype, {
                each : function(callback, bind){
                    planactor.each(this, callback, bind);
                    return this;
                },
                any : function(callback, bind){
                    for(var i=0; i<this.length; ++i){
                        if (true === callback.call(bind, this[i], i, this)){
                            return true;
                        }
                    }
                    return false;
                },
                all : function(callback, bind){
                    for(var i=0; i<this.length; ++i){
                        if (false === callback.call(bind, this[i], i, this)){
                            return false;
                        }
                    }
                    return true;
                }
            });
        }

        if (File && File.prototype){
            /**
             * Html5 파일 객체
             * @class File
             */
            planactor.extend(File.prototype, {
                getRelativePath : function(){
                    return this._relativePath || this.webkitRelativePath;
                },
                /**
                 * 파일 확장자 반환
                 * @memberof File
                 */
                ext : function(){
                    if (!this._extension) this._extension = String(this.name).ext();
                    return this._extension;
                },
                /**
                 * 허용하는 확장자 확인
                 * extString.isAcceptExt('jpg,jpeg')
                 * extString.isAcceptExt('jpg:jpeg',':')
                 * @memberof File
                 * @param string|array chars
                 * @param string split
                 * @return boolean
                 */
                isAcceptExt : function(accept, split){
                    var ext = this.ext();
                    var type = this.type;
                    if (planactor.isArray(accept)){}
                    else if (planactor.isRegexp(accept)){ accept = [accept]; }
                    else{
                        split = split || ',';
                        var exts = planactor.isEmpty(accept) ? '*' : accept;
                        exts = planactor.map(exts.split(split),function(ext, i){
                            ext = ext.trim();
                            var last = ext.lastIndexOf('.');
                            if (-1 == last) return ext;
                            return ext.substr(last+1);
                        });
                        accept = exts.compact(true);
                    }
                    return planactor.any(accept, function(regexp){
                        if (planactor.isRegexp(regexp)){
                            // type 및 ext
                            return (regexp.test(ext) || regexp.test(type));
                        }else{
                            regexp = regexp.trim();
                            if ('*' == regexp){
                                return true;
                            }
                            // ext
                            else if (ext == regexp){
                                return true;
                            }
                            // type
                            else{
                                regexp.replace('*','');
                                regexp = '^'+String(regexp);
                                regexp = new RegExp(regexp,'i');
                                return regexp.test(type);
                            }
                        }
                    });
                },
                /**
                 * 파일 비교
                 * @memberof File
                 */
                equals : function(files){
                    // 동일 파일인지 확인
                    function isEqual(file1, file2){
                        return (
                            file1.size == file2.size &&
                            file1.name == file2.name &&
                            // file1.type == file2.type && // drag와 파일선택에서 얻어오는 file type이 같지 않음 (drag에서 공백)
                            file1.lastModifiedDate.toString() == file2.lastModifiedDate.toString()
                            // file1.lastModified == file2.lastModified // drag와 파일선택에서 얻어오는 file객체가 서로 다름
                        );
                    }
                    var self = this;
                    if (files instanceof Array){
                        return planactor.any(files, function(file){
                            return isEqual(self, file);
                        });
                    }else{
                        return isEqual(self, files);
                    }
                },
                /**
                 * 파일 썸네일 생성 (Canvas 지원)
                 * @ignore
                 * @memberof File
                 * @param {object} options
                 * @example
file.createThumbnail({
    mimetype    : 'image/jpeg',
    quality     : 1,
    width       : 100,
    height      : 100,
    complete    : function(e, dataURL){
        // dataURL 형태로 썸네일 반환
    }
});
                 */
                createThumbnail : function(options){
                    if (this.type.match('image/*')){
                        options = planactor.extend({
                            mimetype    : 'image/jpeg',
                            quality     : 1,
                            width       : 100,
                            height      : 100,
                            complete    : function(e, dataURL){}
                        }, options);
                        try {
                            var objURL = URL.createObjectURL(this);
                            var img = document.createElement('img');
                            img.onload = (function(cimg){
                                return function(e){
                                    var w = cimg.width;
                                    var h = cimg.height;
                                    var resize = planactor.resize(w,h,options.width,options.height);
                                    var canvas = document.createElement('canvas');
                                    canvas.width = resize.width;
                                    canvas.height = resize.height;
                                    var ctx = canvas.getContext('2d');
                                    ctx.drawImage(cimg, 0, 0, resize.width, resize.height);
                                    var dataURL = canvas.toDataURL(options.mimetype, options.quality);
                                    URL.revokeObjectURL(objURL);
                                    // img.src = dataURL;
                                    cimg = objURL = img = null;
                                    options.complete(e, dataURL, ctx);
                                };
                            })(img);
                            img.src = objURL;
                            return true;
                        } catch (e){
                            throw new Error('File.prototype.createThumbnail Error!');
                        }
                    }
                    return false;
                },
                /**
                 * 파일 업로드
                 * @ignore
                 * @memberof File
                 * @example
file.upload({
    url : '/app/index/test',
    data : {'aaa':111},
    type : 'text', // text|arraybuffer|blob|document
    loadstart : function(e, file, percentage, Bps){
        console.log('loadstart', e);
    },
    progress : function(e, file, percentage, Bps){
        console.log('progress', e.loaded.toByteUnit(), percent, Bps.toByteUnit());
    },
    load : function(e, file, response){
        console.log('load', e);
        // this = xhr object
    },
    loadend : function(e, file){
        console.log('loadend', e);
    },
    abort : function(e, file){
        console.log('abort', e);
    },
    timeout : function(e, file){
        console.log('timeout', e);
    }
});
                 */
                upload : function(options){
                    options = planactor.extend({
                        'url'       : '',
                        'name'      : 'file',
                        'data'      : null,
                        // 'mimetype'  : 'text/plain; charset=x-user-defined',
                        'type'      : null,    // text|arraybuffer|blob|document
                        'timeoutsecond' : 0, // 0-무제한
                        'loadstart' : function(e,file){},
                        'progress'  : function(e,file,percentage,Bps){},
                        'load'      : function(e,file,response){},
                        'success'   : function(e,file,response){},
                        'error'     : function(e,file){},
                        'loadend'   : function(e,file){},
                        'abort'     : function(e,file){},
                        'timeout'   : function(e,file){}
                    }, options);

                    var file = this;
                    if (!this.xhr) {
                        this.xhr = new XMLHttpRequest();
                        var stimer;
                        var events = {
                            'loadstart' : function(e){
                                stimer = new Date().getTime();
                                options.loadstart.call(file.xhr,e,file);
                            },
                            'progress'  : function(e){
                                if (e.lengthComputable){
                                    var percentage = Math.round(e.loaded/e.total*100);
                                    var ctimer = (new Date()).getTime();
                                    var Bps = e.loaded/((ctimer-stimer)/1000); // Byte/s
                                    options.progress.call(file.xhr,e,file,{
                                        'loaded' : e.loaded,
                                        'bytes' : e.total,
                                        'percentage' : percentage,
                                        'Bps' : Bps
                                    });
                                }
                            },
                            'load'   : function(e){
                                options.load.call(file.xhr,e,file,this.response);
                                if (4 == this.readyState){
                                    if (200 == this.status){
                                        options.success.call(file.xhr,e,file,this.response);
                                    }else{
                                        options.error.call(file.xhr,e,file);
                                    }
                                }
                            },
                            'loadend'   : function(e){
                                options.loadend.call(file.xhr,e,file,this.response);
                            },
                            'error'     : function(e){
                                options.error.call(file.xhr,e,file);
                            },
                            'abort'     : function(e){
                                options.abort.call(file.xhr,e,file);
                            },
                            'timeout'   : function(e){
                                options.timeout.call(file.xhr,e,file);
                            }
                        };

                        this.xhr.addEventListener('loadstart', events.loadstart, false);
                        this.xhr.upload.addEventListener('progress', events.progress, false);
                        this.xhr.addEventListener('load', events.load, false);
                        this.xhr.addEventListener('loadend', events.loadend, false);
                        this.xhr.addEventListener('error', events.error, false);
                        this.xhr.addEventListener('abort', events.abort, false);
                        this.xhr.addEventListener('timeout', events.timeout, false);
                    }

                    if (planactor.isNotEmpty(options.url)){
                        // post, url, async
                        this.xhr.open('POST',options.url,true);
                        // xhr.setRequestHeader('Content-type','application/x-www-form-urlencoded');
                        // xhr.overrideMimeType(options.mimetype);
                        if (planactor.isNumber(options.timeoutsecond) && options.timeoutsecond>0){
                            this.xhr.timeout = options.timeoutsecond*1000;
                        }
                        if (planactor.isNotEmpty(options.type)){
                            this.xhr.responseType = options.type;
                        }
                        var formData = new FormData();
                        formData.append(options.name, file);
                        if (options.data){
                            planactor.each(options.data, function(val, key){
                                formData.append(key,val);
                            });
                        }
                        this.xhr.send(formData);
                    }
                    return this;
                },
                abort : function(){
                    this.xhr.abort();
                }
            });
        }

        /**
         * planactor.uploader
         * @ignore
         * @memberof planactor
         * @class
         * @see planactor.syncQueue
         * @example
var uploader = new planactor.uploader({
    url : '',
    data : {},
    loadstart : function(){},
    progress : function(){},
    success : function(){},
    complete : function(){}
});
         */
        planactor.uploader = function(options){
            var self = this;
            this.queue = [];
            this.queueSize = 0;
            this.uploadingFile = null;

            this.options = planactor.extend({
                url : null,
                // 업로드 변수명
                name : 'file',
                // 파일 전송시 parameters
                data : {},
                // 최초 파일 업로드 시작 전
                create : function(){},
                // 단일 파일 업로드 시작 시
                loadstart : function(e, file){},
                // 단일 파일 업로드 진행 시
                progress : function(e, file, options){},
                // 단일 파일 업로드 완료
                load : function(e, file, response){},
                success : function(e, file, response){},
                // 에러
                error : function(e, file){},
                next : function(e, file, response){ return true; },
                loadend : function(e, file){},
                // 최종 완료 시
                complete : function(e){},
                // 중지
                abort : function(e){},
            }, options);

            // 모든 파일 한번에 업로드..
            this._multiple = (this.options.name.match(/\[\]$/)) ? true : false;
        };
        planactor.uploader.prototype = {
            _uploadMultiple : function(){
                var self = this, stimer;

                this.xhr = new XMLHttpRequest();
                var events = {
                    'loadstart' : function(e){
                        stimer = new Date().getTime();
                        self.options.loadstart.call(self,e,self.queue);
                    },
                    'progress'  : function(e){
                        if (e.lengthComputable){
                            var percentage = Math.round(e.loaded/e.total*100);
                            var ctimer = (new Date()).getTime();
                            var Bps = e.loaded/((ctimer-stimer)/1000); // Byte/s
                            self.options.progress.call(self,e,self.queue,{
                                'loaded' : e.loaded,
                                'bytes' : e.total,
                                'queueloaded' : e.loaded,
                                'queuebytes' : e.total,
                                'percentage' : percentage,
                                'queuepercentage' : percentage,
                                'Bps' : Bps
                            });
                        }
                    },
                    'load'   : function(e){
                        self.options.load.call(self,e,self.queue,self.response);
                        if (4 == this.readyState){
                            if (200 == this.status){
                                self.options.success.call(self,e,self.queue,self.response);
                            }else{
                                self.options.error.call(self,e,self.queue);
                            }
                        }
                    },
                    'loadend': function(e){
                        self.options.loadend.call(self,e,self.queue);
                        self.options.complete.call(self,self.queue);
                        self.queue.length = 0;
                    },
                    'error'  : function(e){
                        self.options.error.call(self,e,self.queue);
                    },
                    'abort'  : function(e){
                        self.options.abort.call(self,e,self.queue);
                    },
                    'timeout': function(e){
                        error = self.queue;
                        self.options.timeout.call(self,e,self.queue);
                    }
                };

                this.xhr.addEventListener('loadstart', events.loadstart, false);
                this.xhr.upload.addEventListener('progress', events.progress, false);
                this.xhr.addEventListener('load', events.load, false);
                this.xhr.addEventListener('loadend', events.loadend, false);
                this.xhr.addEventListener('error', events.error, false);
                this.xhr.addEventListener('abort', events.abort, false);
                this.xhr.addEventListener('timeout', events.timeout, false);

                // post, url, async
                this.xhr.open('POST',this.options.url,true);
                // xhr.setRequestHeader('Content-type','application/x-www-form-urlencoded');
                // xhr.overrideMimeType(options.mimetype);
                if (planactor.isNumber(this.options.timeoutsecond) && this.options.timeoutsecond>0){
                    this.xhr.timeout = this.options.timeoutsecond*1000;
                }
                if (planactor.isNotEmpty(this.options.type)){
                    this.xhr.responseType = this.options.type;
                }

                var formData = new FormData();
                planactor.each(this.queue, function(val, key){
                    formData.append(self.options.name, val);
                });
                self.options.create.call(self);
                this.xhr.send(formData);
            },
            _uploadSingle : function(){
                var self = this;
                var queueCount = this.queue.length;
                var queueTotalBytes = this.queueSize;
                var loadedTotalBytes = 0;

                // 모든 파일 한번에 업로드..
                this.sync = new planactor.syncQueue({
                    multi : 1,
                    create : function(){
                        self.options.create.call(self);
                        // queueCount = self.queue.length;
                        // queueTotalBytes = self.queueSize;
                        loadedTotalBytes = 0;
                    },
                    complete : function(){
                        self.uploadingFile = null;
                        self.options.complete.call(self);
                    }
                });

                planactor.each(this.queue, function(file, i){
                    self.sync.add(function(callback, idx){
                        var loadedcount = i+1;
                        self.uploadingFile = file;
                        file.upload({
                            url : self.options.url,
                            name : self.options.name,
                            data : self.options.data,
                            loadstart : function(e,file){
                                self.options.loadstart.call(self,e,file);
                            },
                            progress : function(e,file,options){
                                if (e.lengthComputable){
                                    var queueloaded = loadedTotalBytes+options.loaded;
                                    var queuepercentage = Math.round(queueloaded/queueTotalBytes*100);
                                    self.options.progress.call(self,e,file,{
                                        'loadedcount' : loadedcount,
                                        'queuecount' : queueCount,
                                        'loaded' : options.loaded,
                                        'bytes' : options.bytes,
                                        'queueloaded' : queueloaded,
                                        'queuebytes' : queueTotalBytes,
                                        'percentage' : options.percentage,
                                        'queuepercentage' : queuepercentage,
                                        'Bps' : options.Bps
                                    });
                                }
                            },
                            success : function(e,file,response){
                                loadedTotalBytes += file.size;
                                self.options.load.call(self,e,file,response);
                            },
                            error : function(e,file){
                                self.options.error.call(self,e,file);
                            },
                            loadend : function(e,file){
                                self.options.loadend.call(self,e,file);
                                callback();
                            },
                            abort : function(e,file){
                                self.options.abort.call(self,e,file);
                            }
                        });
                    });
                });
                this.sync.start();
            },
            add : function(file){
                var self = this;
                if (file instanceof File){
                    this.queue.push(file);
                    this.queueSize += file.size;
                } else if (planactor.isArray(file)){
                    planactor.each(file,function(val){
                        self.queue.push(val);
                        self.queueSize += val.size;
                    });
                }
                return this;
            },
            remove : function(file){
                if (file instanceof File){
                    var queue = [];
                    this.queue.each(function(val){
                        (!val.equals(file)) && queue.push(val);
                    });
                    this.queue = queue;
                }
                return this;
            },
            clearQueue : function(){
                this.queue.length = 0;
                return this;
            },
            stop : function(){
                this.abort();
            },
            abort : function(){
                if (true === this._multiple){
                    this.xhr && this.xhr.abort();
                }else{
                    this.uploadingFile && this.uploadingFile.abort();
                    this.sync && this.sync.abort();
                }
            },
            abortAll : function(){
                this.abort();
                this.clearQueue();
                !this._multiple && this.sync.clearQueue();
            },
            resend : function(){
                this.sync.start(true);
            },
            send : function(){
                if (this._multiple){
                    this._uploadMultiple();
                }else{
                    this._uploadSingle();
                }
                return this;
            }
        };

        /**
         * 파일 Drag & Drop
         * @ignore
         * @memberof planactor
         * @class
         * @param {HtmlElement} element
         * @param {object} options
         * @example
new planactor.fileDragAndDrop('#drag-list, .drag-box', {
    'accept'    : '.jpg,.jpeg',
    'validdrag' : true,
    'dragenter' : function(e){
        $('.drag-box').css('border-color','red');
    },
    'dragleave' : function(e){
        $('.drag-box').css('border-color','#989898');
    },
    'drop' : function(e, dataTransfer){
        $('.drag-box').hide();
        $('#drag-list').show();
    },
    'file' : function(file, index){
        if (!file.equals(filelist)){
            filelist.push(file);
        }
        console.log(filelist);
    },
    'complete' : function(files, size){},
    'completeTimeout' : 500
});
         */
        planactor.fileDragAndDrop = function(element, options){
            var self = this;
            this.options = planactor.extend({
                'accept'    : '*',  // accept=".gif, .jpg, .png, .doc"
                'ignore'    : ['.db',/^\.|\.$/],
                'maxfilesize'   : 0,  // MB단위 (0:제한없음)
                'error'     : function(status, file){
                    /*
                    switch(status){
                        case planactor.fileDragAndDrop.Exception.DIRECTORY_NOT_SUPPORT: // 폴더드래그를 지원하지 않음.
                            // $.noty.error('현재 브라우저는 <span style="color:white;">폴더인식을 지원하지 않습니다</span>.<br/>업로드 하실 <span style="color:white;">파일만 선택</span>해주세요!<br/><br/>현재 사이트는 <span style="color:white;">구글크롬</span> 브라우저에 <span style="color:white;">최적화</span> 되어 있으며 구글크롬 이용 시 <span style="color:white;">폴더지원도 가능</span>합니다!<br/><br/><a href="http://www.google.com/chrome" target="_blank">구글크롬 다운로드(click)</a>');
                            break;
                        case planactor.fileDragAndDrop.Exception.INVALID_FILETYPE: // 허용하지 않는 확장자파일을 포함할 경우
                            // $.noty.error('허용하지 않는 파일 타입 또는 확장자는 제외되었습니다.<br/>허용하는 파일타입/확장자 : '+this.options.accept+((filelist.length > 0)?'<br/><br/>'+filelist.join('<br/>'):''));
                            break;
                        case planactor.fileDragAndDrop.Exception.MAX_FILE_SIZE_EXCEEDED: // 단일파일 제한 초과
                            // $.noty.error('파일 제한크기('+this.maxfilesize.toByteUnit()+')를 초과한 파일은 제외되었습니다.'+((filelist.length > 0)?'<br/><br/>'+filelist.join('<br/>'):''));
                            break;
                        case planactor.fileDragAndDrop.Exception.ZERO_BYTE_FILE: // 전체파일 제한 초과
                            // $.noty.error('파일크기가 0byte인 파일은 제외되었습니다.'+((filelist.length > 0)?'<br/><br/>'+filelist.join('<br/>'):''));
                            break;
                        default:
                            // $.noty.error('알수없는 에러가 발생하였습니다.');
                    }
                    */
                },
                'dragover'  : function(e){},
                'dragenter' : function(e){},
                'dragleave' : function(e){},
                'drop'      : function(e){},
                'file'      : null, //function(file, index){},
                'complete'  : null, //function(files, size){},
                'completeTimeout' : 500  // 파일탐색 시 완료시점을 잡기위한 timeout
            }, options);

            // 폴더 traverse
            this.completeHandler = null;

            // 단일파일 제한
            this.maxfilesize = this.options.maxfilesize*1024*1024;

            this.$element = document.querySelector(element);

            self.overcount = 0;
            this.$element.addEventListener('dragenter', function(e){
                e.stopPropagation();
                e.preventDefault();
                if (0 == self.overcount) self.options.dragenter.call(self,e);
                ++self.overcount;
            }, false);
            this.$element.addEventListener('dragleave', function(e){
                e.stopPropagation();
                e.preventDefault();
                --self.overcount;
                if (0 == self.overcount) self.options.dragleave.call(self,e);
            }, false);
            this.$element.addEventListener('dragover', function(e){
                e.stopPropagation();
                e.preventDefault();
                self.options.dragover.call(self,e);
            }, false);
            this.$element.addEventListener('drop', function(e){
                // e.stopPropagation();
                e.preventDefault();

                var queue = [], queueIndex = 0, queueSize = 0;
                // drop event
                this.options.drop.call(this, e);

                // accept files callback 및 valid
                this.traverseEntry(e.dataTransfer, function(file){
                    clearTimeout(this.completeHandler);
                    // 파일무시
                    if (!file.isAcceptExt(this.options.ignore)){
                        // 허용파일
                        if (file.isAcceptExt(this.options.accept)){
                            // 단일파일 제한
                            if (0 == this.maxfilesize || this.maxfilesize >= file.size){
                                if (file.size > 0){
                                    file.id = 'queue_'+(new Date()).getTime()+'_'+queueIndex;
                                    ++queueIndex;
                                    queue.push(file);
                                    queueSize += file.size;
                                    // file event
                                    planactor.isFunction(this.options.file) && this.options.file.call(this, file, queueIndex);
                                }else{
                                    // 파일크기가 0byte인 파일제외
                                    this.options.error.call(this, planactor.fileDragAndDrop.Exception.ZERO_BYTE_FILE, file);
                                }
                            }else{
                                // 단일파일 제한 초과로 제외된 파일목록
                                this.options.error.call(this, planactor.fileDragAndDrop.Exception.MAX_FILE_SIZE_EXCEEDED, file);
                            }
                        } else {
                            // 허용하는 확장자 제한으로 제외된 파일목록
                            this.options.error.call(this, planactor.fileDragAndDrop.Exception.INVALID_FILETYPE, file);
                        }
                    }

                    if (planactor.isFunction(this.options.complete)){
                        this.completeHandler = setTimeout(function(){
                            // complete event
                            this.options.complete.call(this, queue, queueSize);
                        }.bind(this), this.options.completeTimeout);
                    }

                }.bind(this));
                // drop 후 dragleave 자동호출
                this.options.dragleave.call(this, e);

            }.bind(this), false);
        };
        planactor.fileDragAndDrop.Exception = {
            'DIRECTORY_NOT_SUPPORT' : 'DIRECTORY_NOT_SUPPORT',
            'INVALID_FILETYPE'      : 'INVALID_FILETYPE',
            'MAX_FILE_SIZE_EXCEEDED': 'MAX_FILE_SIZE_EXCEEDED',
            'ZERO_BYTE_FILE'        : 'ZERO_BYTE_FILE'
        };
        planactor.fileDragAndDrop.prototype = {
            // 디렉토리인지 확인
            isDirectory : function(file){
                if (!file.name.ext() && !file.type && file.size <= 102400) return true;
                return false;
            },
            // Chrome items 또는 기타 브라우저 files 대한 filelist 반환
            traverseEntry : function(dataTransfer, callback){
                // Chrome & Opera
                var items = dataTransfer.items;
                if (items){
                    for (var i=0, item; item = items[i]; ++i) {
                        item = item.webkitGetAsEntry();
                        this.traverseFileTree(item, '', function(file){
                            callback(file);
                        });
                    }
                }
                // FF & Safari & IE
                else{
                    var files = dataTransfer.files;
                    // IE
                    if (0 == files.length){
                        this.options.error.call(this, planactor.fileDragAndDrop.Exception.DIRECTORY_NOT_SUPPORT);
                        return this;
                    }
                    // FF & Safari
                    else{
                        // 파일 확장자가 없고 파일 타입이 지정되지 않은 102400보다 작은 파일객체는 폴더로 인식
                        var error = [];
                        for(var i=0, file; file = files[i]; ++i){
                            // 폴더를 지원하지 않는 브라우저에서 폴더 드래그 시
                            if (true === this.isDirectory(file)){
                                this.options.error.call(this, planactor.fileDragAndDrop.Exception.DIRECTORY_NOT_SUPPORT, file);
                            }else{
                                callback(file);
                            }
                        }
                    }
                }
                return this;
            },
            // Chrome itementry 탐색
            traverseFileTree : function(item, path, callback) {
                clearTimeout(this.completeHandler);
                path = path || '';
                if (item.isFile) {
                    item.file(function(file){
                        if (path.length > 0) file._relativePath = path + file.name;
                        file._fullPath = item.fullPath;
                        callback(file);
                    });
                } else if (item.isDirectory) {
                    var dirReader = item.createReader();
                    this.directoryEntry(dirReader, item, path, callback);
                }
                return this;
            },
            // readEntries는 한번에 100개씩만 얻어오기 때문에 순환함수로 구현
            directoryEntry : function(dirReader, item, path, callback){
                dirReader.readEntries(function(entries) {
                    if (entries.length > 0){
                        for(var i=0, entry; entry = entries[i]; ++i){
                            this.traverseFileTree(entry, path + item.name + "/", callback);
                        }
                        this.directoryEntry(dirReader, item, path, callback);
                    }
                }.bind(this));
                return this;
            }
        };
    }
})(planactor);
